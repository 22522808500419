import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { getAllTeam, getTeam } from "../../services/contactMemberService";
import {
  getTicketBotbyBusinessUid,
  getTicketConfig,
  postTicketConfig,
} from "../../services/ticketService";
import { customStyles } from "../../common/react-select-style";
import { toast } from "../../common/alert";
import "./ticketing.scss";
import Loader from "../../shade/Loaders/smallLoader";

interface Option {
  label: string;
  value: string | number;
}

const TicketConfiguration: React.FC = () => {
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid.value
  );

  const animatedComponents = makeAnimated();

  const [teams, setTeams] = useState<Option[]>([]);
  const [members, setMembers] = useState<Option[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
  const [selectedMember, setSelectedMember] = useState<string | null>(null);
  const [initialValues, setInitialValues] = useState<any>({
    uid: "",
    team: "",
    member: "",
    bot: "",
    overrideTeam: false,
  });
  const [bots, setBots] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [submitDisable, setSubmitDisable] = useState<boolean>(true);

  const getTeams = async () => {
    try {
      const payload = {
        businessUid: businessId,
        page: 0,
        limit: 1000,
      };
      const team = await getAllTeam(payload, "ticketing");
      const teams = team.data.map((teamMember: any) => ({
        label: teamMember.name,
        value: teamMember.uid,
      }));

      setTeams(teams);
    } catch {
      setTeams([]);
    }
  };

  const getMembers = async (teamId: string) => {
    try {
      const team = await getTeam(teamId, "ticketing");
      const members = team.members.map((mem: any) => ({
        label: mem.name,
        value: mem.uid,
      }));

      setMembers(members);
    } catch {
      setMembers([]);
    }
  };

  const handleTeamChange = async (option: Option, formikProps: any) => {
    const { field, form } = formikProps;
    if (option.value.toString() !== selectedTeam) {
      setSelectedTeam(option.value.toString());
      await getMembers(option.value.toString());
      form.setFieldValue("member", "");
    }
    form.setFieldValue(field.name, option?.value);
  };

  const fetchInitialConfig = async () => {
    try {
      const response = await getTicketConfig(channelUid);
      const configData = response;

      const botResponse = await getTicketBotbyBusinessUid(businessId);

      const botOptions = botResponse.map((bot: any) => ({
        label: bot.name,
        value: bot.uid,
      }));

      setBots(botOptions);

      const newInitialValues = {
        uid: configData.uid ? configData.uid : "",
        team: configData.team ? configData.team.uid : "",
        member: configData.member ? configData.member.uid : "",
        bot: configData.bot ? configData.bot.uid : "",
        overrideTeam: configData.overrideTeam || false,
      };

      setInitialValues(newInitialValues);

      if (configData.team && configData.team.uid) {
        await getMembers(configData.team.uid);
      }

      setSelectedTeam(configData.team ? configData.team.uid : null);
      if (configData.member) {
        setSelectedMember(configData.member.uid);
      }

      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if(channelUid){
      getTeams();
      fetchInitialConfig();
    }
  }, [businessId,channelUid]);

  const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    const data: any = {
      id: 1,
      uid: values.uid ? values.uid : null,
      bot: values.bot ? { uid: values.bot } : null,
      team: values.team ? { uid: values.team } : null,
      member: values.member ? { uid: values.member } : null,
      overrideTeam: values.overrideTeam,
    };

    try {
      await postTicketConfig(values.uid, data);
      setSubmitting(false);
      setSubmitDisable(true);
    } catch (error) {
      toast("error", "Failed to update configuration");
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="ticketConfiguration m-2" style={{ height: '100vh' }}>
      <h5 className="mt-2 mb-1">Configuration</h5>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue }) => (
          <Form>
            <div style={{ width: "30%" }}>
              <div className="mb-3 mt-3">
                <label className="labelstyle">Select Bot</label>
                <Field name="bot">
                  {({ field }: any) => (
                    <Select
                      options={bots}
                      className="pt-1"
                      components={animatedComponents}
                      value={bots.find((option) => option.value === field.value) || null}
                      placeholder="Select Bot..."
                      onChange={(option: any) => {
                        setFieldValue(field.name, option?.value);
                        setSubmitDisable(false);
                      }}
                      styles={customStyles}
                    />
                  )}
                </Field>
              </div>
              <div className="mb-3">
                <label className="labelstyle">Select Team</label>
                <Field name="team">
                  {({ field, form }: any) => (
                    <Select
                      options={teams}
                      className="pt-1"
                      components={animatedComponents}
                      value={teams.find((option) => option.value === field.value) || null}
                      placeholder="Select Team..."
                      onChange={(option: any) => {
                        handleTeamChange(option, { field, form });
                        setSubmitDisable(false);
                      }}
                      styles={customStyles}
                    />
                  )}
                </Field>
              </div>
              <div className="mb-3">
                <label className="labelstyle">Select Team Lead</label>
                <Field name="member">
                  {({ field }: any) => (
                    <Select
                      options={members}
                      className="pt-1"
                      components={animatedComponents}
                      value={members.find((option) => option.value === field.value) || null}
                      placeholder="Select Member..."
                      onChange={(option: any) => {
                        setFieldValue(field.name, option?.value);
                        setSelectedMember(option?.value);
                        setSubmitDisable(false);
                      }}
                      styles={customStyles}
                      isDisabled={!selectedTeam}
                    />
                  )}
                </Field>
              </div>
              <div style={{ width: '100%' }}>
                <label style={{ width: '90vw' }}>
                  <Field
                    type="checkbox"
                    name="overrideTeam"
                    className="me-2"
                    onChange={(e: any) => {
                      setFieldValue("overrideTeam", e.target.checked);
                      setSubmitDisable(false);
                    }}
                  />
                  Enable automatic assignment of tickets to specified teams based on company settings when tickets are created by contacts from those companies.
                </label>
              </div>
            </div>

            <div style={{ cursor: !submitDisable ? "pointer" : "not-allowed" }}>
              <Button
                type="submit"
                className="signUpBtn mt-5 col-md-1 col-xl-1"
                disabled={submitDisable}
                style={{
                  cursor: !submitDisable ? "pointer" : "not-allowed",
                  opacity: !submitDisable ? 1 : 0.8,
                }}
              >
                Apply
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TicketConfiguration;