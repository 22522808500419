import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Popover, PopoverBody, Row } from "reactstrap";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import "../main.scss";
import Shiprocket from "./IntegrationTypes/Shiprocket";
import ZohoBooks from "./IntegrationTypes/ZohoBooks";
import {
  createConnectionBasedOnTypes,
  deletIntegrationBasedOnIntegrationUid,
} from "../../../services/integrationService";
import { useSelector } from "react-redux";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { faEllipsisVertical, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "../../../common/alert";
import { AiOutlineEyeInvisible } from "react-icons/ai";

interface LocationState {
  imgSrc: string;
  description: string;
  connectionUid: string;
}

const IntegrationDetails = () => {
  const { name, integrationUid } = useParams<{
    name: string;
    integrationUid: string;
  }>();
  const location = useLocation();
  const state = location?.state as LocationState;
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const navigate = useNavigate();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Overview");
  const [callDb, setCallDb] = useState(false);
  const [workFlowEnable, setWorkFlowEnable] = useState(false);

  useEffect(() => {
    if (typeof integrationUid === "string") {
      setActiveTab("Configuration");
    } else {
      setActiveTab("Overview");
    }
  }, [integrationUid]);

  const initialValues = {
    integrationName: "",
    userEmail: "",
    userPassword: "",
  };

  const validationSchema = Yup.object({
    integrationName: Yup.string().required("Integration Name is required"),
    userEmail: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    userPassword: Yup.string().required("Password is required"),
  });

  const handleSubmit = async (
    values: typeof initialValues,
    formikHelpers: FormikHelpers<typeof initialValues>
  ) => {
    const data = {
      name: values.integrationName,
      integrationTypes: "sprocket",
      shipRocket: {
        email: values.userEmail,
        password: values.userPassword,
      },
    };

    try {
      const response = await createConnectionBasedOnTypes(businessId, data);
      formikHelpers.resetForm();
      setPopoverOpen(false);
      navigate(`/integrations/${name}/edit/${response.uid}`, {
        state: {
          imgSrc: state.imgSrc,
          description: state.description,
          connectionUid: response.connection.uid,
        },
      });
      setActiveTab("Configuration");
    } catch (error) {
      console.error("Error creating connection:", error);
    }
  };

  const togglePopover = (resetForm?: () => void) => {
    setPopoverOpen(!popoverOpen);
    if (!popoverOpen && resetForm) {
      resetForm();
    }
  };

  const integrationTabs = ["Overview", "Configuration", "Workflow"];
  const displayTabs = integrationUid ? integrationTabs : ["Overview"];

  const deleteIntegration = async (integrationUid: string) => {
    confirmAlert(
      "warning",
      "Are you sure you want to delete this integration?",
      async () => {
        await deletIntegrationBasedOnIntegrationUid(integrationUid);
        navigate("/integrations", { state: { update: true } });
      },
      () => {}
    );
  };

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="integrationDetails">
      <div className="integrationOverview">
        <div
          onClick={() => navigate("/integrations")}
          style={{ cursor: "pointer" }}
        >
          Integration {">"}
        </div>
        <div className="selected-type">{name === "sprocket" ? "Shiprocket" : name}</div>
      </div>
      <div className="integrationDetails-header">
        <Row>
          <Col
            md={1}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <img src={state?.imgSrc} alt={name} />
            </div>
          </Col>
          <Col
            md={integrationUid ? 7 : 9}
            className="d-flex align-items-center"
          >
            <div>
              <h5 className="fw-bold">{name === "sprocket" ? "Seamlessly Connect ShipRocket with BizMagnets!" : name === 'Zoho Books' ? 'Zoho Books Integration' : name}</h5>
              <div className="description">{state?.description}</div>
            </div>
          </Col>
          <Col
            md={integrationUid ? 4 : 2}
            className={`d-flex justify-content-${
              integrationUid ? "end" : "start"
            } align-items-center`}
          >
            <div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ resetForm }) => (
                  <>
                    {integrationUid ? (
                      <>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="me-3 lastUpdate">Status :</div>
                          <div className="connectionConnected">Connected</div>
                          <div className="d-flex align-items-center mt-2 ms-1">
                            <UncontrolledDropdown group>
                              <DropdownToggle
                                className="delete-toggle"
                                style={{ background: "none", border: "none" }}
                              >
                                <FontAwesomeIcon
                                  icon={faEllipsisVertical}
                                  className="pt-1"
                                />
                              </DropdownToggle>
                              <DropdownMenu className="ctxMenu">
                                <DropdownItem className="ctxMenuItem itemsColor">
                                  <div
                                    onClick={() => {
                                      deleteIntegration(integrationUid);
                                    }}
                                    style={{color: '#00b598'}}
                                  >
                                    <FontAwesomeIcon
                                      className="mr-2"
                                      icon={faTrash}
                                      color="#00b598"
                                    />
                                    Delete
                                  </div>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Button
                          id="Popover1"
                          type="button"
                          onClick={() => togglePopover(resetForm)}
                          className="connect-btn"
                        >
                          Connect
                        </Button>
                        <Popover
                          placement="bottom"
                          isOpen={popoverOpen}
                          target="Popover1"
                          toggle={() => togglePopover(resetForm)}
                        >
                          <PopoverBody>
                            <Form>
                              <div className="form-group">
                                <label htmlFor="integrationName">
                                  Enter Integration Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="integrationName"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="integrationName"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="userEmail">
                                  Shiprocket API User Email{" "}
                                  <span className="text-danger">*</span> <br />
                                  <a
                                    href="https://app.shiprocket.in/login?routestate=api-user"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Where to get{" "}
                                    <svg
                                      stroke="currentColor"
                                      fill="none"
                                      strokeWidth="2"
                                      viewBox="0 0 24 24"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      focusable="false"
                                      className="chakra-icon css-13otjrl"
                                      aria-hidden="true"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                      <polyline points="15 3 21 3 21 9"></polyline>
                                      <line
                                        x1="10"
                                        y1="14"
                                        x2="21"
                                        y2="3"
                                      ></line>
                                    </svg>
                                  </a>
                                </label>
                                <Field
                                  type="email"
                                  name="userEmail"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="userEmail"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="userPassword">
                                  Shiprocket API User Password{" "}
                                  <span className="text-danger">*</span>{" "}
                                  <a
                                    href="https://app.shiprocket.in/login?routestate=api-user"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Where to get{" "}
                                    <svg
                                      stroke="currentColor"
                                      fill="none"
                                      strokeWidth="2"
                                      viewBox="0 0 24 24"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      focusable="false"
                                      className="chakra-icon css-13otjrl"
                                      aria-hidden="true"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                      <polyline points="15 3 21 3 21 9"></polyline>
                                      <line
                                        x1="10"
                                        y1="14"
                                        x2="21"
                                        y2="3"
                                      ></line>
                                    </svg>
                                  </a>
                                </label>
                                <div className="password-field">
                                  <Field
                                    type={passwordVisible ? "text" : "password"}
                                    name="userPassword"
                                    className="form-control"
                                  />
                                  <span
                                    className="password-toggle"
                                    onClick={togglePasswordVisibility}
                                  >
                                    {passwordVisible ? (
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                      </svg>
                                    ) : (
                                      <AiOutlineEyeInvisible />
                                    )}
                                  </span>
                                </div>
                                <ErrorMessage
                                  name="userPassword"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                              <div className="d-flex justify-content-end mt-3">
                                <Button
                                  type="button"
                                  onClick={() => {
                                    resetForm();
                                    togglePopover();
                                  }}
                                  className="cancel-btn"
                                >
                                  Cancel
                                </Button>
                                <Button type="submit" className="submit-btn">
                                  Submit
                                </Button>
                              </div>
                            </Form>
                          </PopoverBody>
                        </Popover>
                      </>
                    )}
                  </>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </div>

      <div className="integration-body">
        <div className="integration-tabs">
          {displayTabs.map((tab: any) => {
            if (tab === "Workflow" || tab === "Logs") {
              return (
                <div
                  key={tab}
                  className={`tab-${activeTab === tab ? "active" : ""}`}
                  onClick={(e: any) => {
                    if (workFlowEnable) {
                      setActiveTab(tab);
                    } else {
                      e.preventDefault();
                    }
                  }}
                  style={{
                    cursor: workFlowEnable ? "pointer" : "not-allowed",
                    opacity: workFlowEnable ? 1 : 0.5,
                  }}
                >
                  {tab}
                </div>
              );
            } else {
              return (
                <div
                  key={tab}
                  className={`tab-${activeTab === tab ? "active" : ""}`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </div>
              );
            }
          })}
        </div>
        <div className="integration-content">
          {name === "sprocket" && (
            <Shiprocket
              integrationUid={integrationUid}
              connectionUid={state.connectionUid}
              activeTab={activeTab}
              callDb={callDb}
              setCallDb={setCallDb}
              setWorkFlowEnable={setWorkFlowEnable}
              setActiveTab={setActiveTab}
            />
          )}

          {
            name === "zohoBooks" && (
              <ZohoBooks
                integrationUid={integrationUid}
                connectionUid={state.connectionUid}
                activeTab={activeTab}
                callDb={callDb}
                setCallDb={setCallDb}
                setWorkFlowEnable={setWorkFlowEnable}
                setActiveTab={setActiveTab}
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default IntegrationDetails;
