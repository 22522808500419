import React, { useState, useEffect, useContext } from 'react';
import { createPortal } from 'react-dom';
import { Handle, Position } from 'reactflow';
import { MdContentCopy, MdLock, MdModeEdit, MdOutlineClose } from 'react-icons/md';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import classes from './node.module.scss';
import Editor from '../editor';
import { useAppDispatch } from '../../../hooks';
import { deleteNode, duplicateNode, setNodeData } from '../../../store/graphSlice';
import flowContext from '../../../context/flowContext';
import { toast } from '../../../../common/alert';
import { useParams } from 'react-router-dom';
import * as designFlowService from '../../../services/designFlow';
import initialContext from '../editor/useContext';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface NodeProps {
    label: string;
    data: { type: string, location: { latitude: number }, locked?: boolean };
    content: any;
    id: string;
    type: string;
    subType?: string;
    children?: React.ReactNode;
    showRight?: boolean;
    selected?: boolean;
    readOnly?: boolean;
    setTitle: (value: any) => void;
}

export const NodeWrapper: React.FC<NodeProps> = (props: NodeProps) => {
    const [showEdit, setShowEdit] = useState(false);
    const flowCtx = useContext(flowContext);
    const initial = useContext(initialContext)
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();
    const [propsId, setPropsId] = useState(props.id);
    const { flowId } = useParams();
    async function deleteElement() {
        const { isConfirmed } = await MySwal.fire({
            title: 'Are you sure',
            text: 'You want to delete this element?',
            confirmButtonText: 'Yes',
            showDenyButton: true,
            customClass: {
                denyButton: classes.cancelButton,
                confirmButton: classes.acceptButton
            }
        })

        if (isConfirmed) {
            dispatch(deleteNode({ id: props.id }));
            setShowEdit(false);
            toast("success", "Flow deleted successfully");
        }
    }
    const handleDeleteKeyPress = (event: KeyboardEvent) => {
        if (props.selected && !showEdit && event.key === 'Delete') {
            deleteElement();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleDeleteKeyPress);
        return () => {
            window.removeEventListener('keydown', handleDeleteKeyPress);
        };
    }, []);

    async function elementLock() {
        const isLocked = props?.data?.locked;
        const lock = isLocked ? 'Unlock' : 'Lock';
        const { isConfirmed } = await MySwal.fire({
            title: 'Are you sure',
            text: `You want to ${lock} this element?`,
            confirmButtonText: 'Yes',
            showDenyButton: true,
            customClass: {
                denyButton: classes.cancelButton,
                confirmButton: classes.acceptButton
            }
        })

        if (isConfirmed) {
            const newData = { ...props.data, locked: !isLocked };
            dispatch(setNodeData({ data: newData, id: props?.id, create: false }));
        }
    }

    async function duplicateElement() {

        initial.isSave = false;
        dispatch(duplicateNode({ id: props.id }));
        setPropsId(localStorage.getItem('dupId') as string);
        initial.duplicate = true;
        setPropsId(localStorage.getItem('dupId') as string);
        setShowEdit(true);
    }

    const cssClasses = [classes.wrapper];


    const deleteDuplicate = async () => {

        if (initial.isSave === false) {
            await designFlowService.deleteFlow("", 0, localStorage.getItem('dupId') as string);
            dispatch(deleteNode({ id: localStorage.getItem('dupId') as string }));
        }
    }


    const handleChanges = () => {
        setPropsId(props.id);
        deleteDuplicate();
        initial.duplicate = false;
    }

    const renderTooltip = (props: NodeProps) => (
        <Tooltip {...props}>
            {props?.data?.locked ? 'Unlock' : 'Lock'}
        </Tooltip>
    );

    return (
        <>
            {(!flowCtx.readOnly && !props.readOnly) ?
                createPortal(<Editor
                    show={showEdit}
                    onHide={() => {
                        setShowEdit(false)
                        initial.edit = false;
                        handleChanges()
                    }}
                    id={propsId}
                    type={props.subType ? `${props.type}/${props.subType}` : props.type}
                />, document.body) : null}
            <div className={cssClasses.join(' ')}>
                {(!flowCtx.readOnly && !props.readOnly) ? (
                    <>
                        <div
                            className={[classes.options, 'nodeHoverOptions'].join(' ')}
                            onMouseEnter={() => {
                                if (props.label.length > 13) {
                                    props.setTitle(props.label.substring(0, 12) + '...');
                                }
                            }}
                            onMouseLeave={() => {
                                props.setTitle(props.label);
                            }}
                        >
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip(props)}
                            >
                                <button
                                    className={`${props?.data?.locked ? '' : classes.opacity}`}
                                    onClick={elementLock}
                                >
                                    <MdLock />
                                </button>
                            </OverlayTrigger>

                            <span className={classes.opacity}>
                                <button onClick={duplicateElement}><MdContentCopy /></button>
                                <button onClick={() => { setShowEdit(true); initial.edit = true; }}><MdModeEdit /> </button>
                                <button onClick={deleteElement}><MdOutlineClose /></button>
                            </ span>
                        </div>
                    </>
                ) : null}

                <div onDoubleClick={() => {
                    if (!flowCtx.readOnly && !props.readOnly) {
                        initial.edit = true;
                        setShowEdit(true);
                    }
                }}>
                    {props.children}
                </div>
                {!props.readOnly ? (
                    <>
                        <Handle
                            position={Position.Left}
                            id='a'
                            type='target'
                            isConnectable
                        />
                        {(props.type !== "open_ai" && props.type !== "jump_to") ? (
                            <Handle
                                position={Position.Right}
                                style={{ display: 'block', top: '10px' }}
                                id='b'
                                type='source'
                                isConnectable
                            />
                        ) : null}
                    </>
                ) : null}
            </div>
        </>
    );
}

NodeWrapper.defaultProps = {
    showRight: true,
    readOnly: false,
    // noUpdate: false
};

function Node(props: NodeProps) {
    const isLocation = props.data.location;
    const cssClasses = isLocation ? [classes.cardC] : [classes.card];
    const notes = props.type === 'actions/notes'
    notes && cssClasses.push(classes.notes);
    const [label, setLabel] = useState(props.label);

    if (props.readOnly) {
        cssClasses.push(classes.readOnly);
    }

    const setTitle = (value: any) => {
        setLabel(value);
    }

    return (
        <NodeWrapper {...props} setTitle={(value: any) => setTitle(value)}>
            <div className={cssClasses.join(' ')}
                onMouseEnter={() => {
                    if (label.length > 13) {
                        setTitle(label.substring(0, 12) + '...');
                    }
                }}
                onMouseLeave={() => {
                    setTitle(props.label);
                }}>
                {isLocation !== undefined ? (
                    <div className={classes.width}>
                        {label}
                    </div>
                ) : (
                    <div className={classes.title}>
                        {label}
                    </div>
                )}
                <div className={classes.body}>
                    {props.content}
                </div>
            </div>
        </NodeWrapper>
    );
}

Node.defaultProps = {
    showRight: true,
    readOnly: false,
};

export default Node;