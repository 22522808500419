import React, { useMemo, useEffect, useState } from "react";
import { sliceText } from "../../common/sliceText";
import { renderMessage } from "../../common/commonfns";
import "./template.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList,
  faExternalLinkAlt, 
  faReply} from "@fortawesome/free-solid-svg-icons";
import phone from "../../assets/img/phonesymbol.png";
import { FaRegCopy } from "react-icons/fa";

interface SingleTemplateProps {
  singleTempData: any;
  component: string;
  selectedConversation?: any;
  status?: number;
}

const SingleTemplate: React.FC<SingleTemplateProps> = ({
  singleTempData,
  component,
  status,
}) => {
  const [templateData, mapTemplateData] = useState<any>([]);
  
  
  useEffect(() => {
    if (component === "conversation") {
      mapTemplateData(singleTempData?.action);

    } else {

      mapTemplateData(singleTempData.components);
    }
  }, [singleTempData]);
  
  return (
    <div>
      <h5>{singleTempData?.showName}</h5>
      <div
        className={`p-2 ${component === "chatcam"
          ? "selectBackground"
          : status === -1
            ? "failedMsgBg"
            : "convBackground"
          }`}
          style={{borderRadius: '10px 0px 10px 10px'}}
      >
        {singleTempData?.isCatalog && singleTempData?.buttonColumns && (
          <img
            src={singleTempData?.buttonColumns[0]}
            className="headerImg"
            alt="catalogImg"
          />
        )}

        {/* Render TEXT, HEADER, BODY, and FOOTER content */}
        {templateData?.map((tempDet: any, tempind: number) => {
          if (tempDet.type === "HEADER") {
            if (tempDet.format === "TEXT") {
              return (
                <div className="fontWeight" key={tempind}>
                  {tempDet.text}
                </div>
              );
            } else if (tempDet.format === "IMAGE") {
              return (
                <img
                  key={tempind}
                  src={tempDet?.example?.header_handle[0]}
                  className="headerImg"
                  alt="oldimage"
                />
              );
            } else if (tempDet.format === "VIDEO") {
              return (
                <video
                  key={tempind}
                  controls
                  src={tempDet?.example?.header_handle[0]}
                  className="headerImg"
                />
              );
            } else if (tempDet.format === "DOCUMENT") {
              return (
                <iframe
                  src={tempDet?.example?.header_handle[0]}
                  className="headerImg"
                ></iframe>
              );
            }
          }

          if (tempDet.type === "BODY" && singleTempData.category!=="AUTHENTICATION") {
            return (
              <div className={`${singleTempData.category ? 'pt-3' : ''}`} key={tempind}>
                {renderMessage(tempDet, tempDet.text, "conversation", status)}
              </div>
            );
          } 
          // if (tempDet?.type === "BODY" && singleTempData?.category==="AUTHENTICATION") {
          //   const message = singleTempData?.buttonColumns && singleTempData?.buttonColumns[0]?.replace(/\n/g, "") ;
          //   return (
          //     <div className="pt-3" key={tempind}>
          //       {renderMessage(tempDet, message, "conversation", status)}
          //     </div>
          //   );
          // } 
          // ---------------AUTHENTICATION OTP & COPY CODE-------------------//
          if (
            tempDet?.type === "BUTTONS" &&
            Array.isArray(tempDet.buttons) && tempDet.buttons.length > 0 &&
            tempDet.buttons[0]?.type?.toUpperCase() === "OTP" &&
            tempDet.buttons[0]?.otp_type?.toUpperCase() === "COPY_CODE" && 
            component === 'conversation'
          ) {
            const security = Array.isArray(singleTempData?.action) &&
            singleTempData?.action[0]?.type === "BODY" &&
            singleTempData?.action[0]?.add_security_recommendation === true;
            
            const message = security 
              ? `${tempDet.buttons[0]?.example ?? tempDet.buttons[0]?.text} is your verification code.` 
              : `${tempDet.buttons[0]?.example ?? tempDet.buttons[0]?.text} is your verification code. For your security, do not share this code.`;
            
            return (
              <div className="" key={tempind}>
                {renderMessage(tempDet, message, "conversation")}
              </div>
            );
          }
          
          if (tempDet.type === "FOOTER") {
            return (
              <div 
                // className="pt-2 textstyle1"
                className={`${tempDet.text ? `pt-2 textstyle1` : ''}`}
                key={tempind}>
                <i>{tempDet.text}</i>
              </div>
            );
          }
          // if (tempDet.type === "BUTTONS" && tempDet.buttons[0]?.type==="otp" &&component!=="chatcam") {
          //   const security=singleTempData?.action&&singleTempData?.action[0]?.type==="BODY" && singleTempData?.action[0]?.add_security_recommendation===true;
          //   return (
          //     singleTempData.action && (
          //       <div className="" key={tempind}>
          //         <i>{tempDet.buttons[0].text} is your verification code</i>
          //         {security && (
          //           <div className="textstyle1">
          //             <i>For your security recommendations, do not share this code.</i>
          //           </div>
          //         )}
          //       </div>
          //     )
          //   );
          // }

          // ---------------AUTHENTICATION OTP & COPY CODE-------------------//
          
      if (
        tempDet.type === "BUTTONS" &&
        tempDet.buttons[0]?.type?.toUpperCase() === "OTP" &&
        component === "chatcam" &&
        tempDet.buttons[0]?.otp_type?.toUpperCase() === "COPY_CODE" 
        // &&
        // tempDet.buttons[0]?.text === "Copy"
      ) {
        const security = Array.isArray(singleTempData?.components) &&
        singleTempData?.components[0]?.type === "BODY" &&
        singleTempData?.components[0]?.add_security_recommendation === true;
        const sequenceText =  singleTempData?.components[0]?.example?.body_text[0] ?? '';
         const text = tempDet.buttons[0]?.example ? tempDet.buttons[0]?.example : sequenceText ? sequenceText : tempDet.buttons[0]?.text;
        const message = security 
        ? ` {${text}} is your verification code.` 
        : `{${text}} is your verification code. For your security, do not share this code.`;
        return (
            <div className="" key={tempind}>
              {message}
            </div>
        );
      }

          // if(tempDet.type==="BODY" && tempDet.add_security_recommendation){
          //   return(
          //     <div className="pt-2 textstyle1" key={tempind}>
          //       <i>For your security recommendation,do not share this code</i>
          //     </div>
          //   )
          // }
          return null; 
        })}
        {/* {singleTempData?.action && singleTempData?.action[2]?.type==="BUTTON" && singleTempData?.action[2]?.type==="OTP" &&(
            <div className="">
            <i>{singleTempData?.action[2]?.buttons[0]?.text} is your verification code</i>
          </div>
        ) } */}
  {component === 'conversation' && ((templateData[1]?.type === 'CAROUSEL' && templateData[1]?.type) ||  (templateData[0]?.type === 'CAROUSEL' && templateData[0]?.type))? 
   <div className={`singleCarousel col-11  p-1`}>
   <div className="d-flex">
     {(templateData[1]?.cards || templateData[0]?.cards)?.map((e: any, ind: number) => (
       <div key={ind} className="templateRow">
        <div className="">
         <span>
          {e?.components[0]?.format === 'IMAGE'?
           <img src={e?.components[0]?.example?.header_handle[0]} className="" width={170} height={90} alt="Header" /> :
           e?.components[0]?.format === 'VIDEO'? <video  src={e?.components[0]?.example?.header_handle[0]}
                                              controls
                                              width={170}
                                              height={90}
                                          /> : ''}
         </span>
         {e?.components[1]?.text && <div title={e?.components[1]?.text}>{e?.components[1]?.text.length > 15 && e?.components[1]?.text ? e?.components[1]?.text?.slice(0,14) + '...' : e?.components[1]?.text}</div>}
         </div>
         <div className="pt-2">
           {e?.components[2]?.buttons?.map((ele: any, buttonIndex: number) => (
             <p key={buttonIndex} className="quickbtns m-1 text-center" title={ele?.text}>
                {ele?.type === 'QUICK_REPLY'? '' : ele?.type === 'PHONE_NUMBER'? <img src={phone} width={20} /> : ele?.type === 'URL'? <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1"/> : '' }  
                {ele?.text?.length > 15? ele?.text?.slice(0,14)+'...' : ele?.text}</p>
           ))}
         </div>
       </div>
     ))}
   </div>
 </div> : ''}
{singleTempData?.category === 'MARKETING' && ((singleTempData?.components[1]?.type === 'CAROUSEL' && singleTempData?.components[1]?.type) || singleTempData?.components[0]?.type === 'CAROUSEL' && singleTempData?.components[0]?.type)? 
     <div className={`singleCarousel col-11  p-1`}>
     <div className="d-flex">
       {(singleTempData?.components[1].cards || singleTempData?.components[0].cards)?.map((e: any, ind: number) => (
         <div key={ind} className="templateRow">
          <div className="">
           <span>
            {e?.components[0]?.format === 'IMAGE'?
             <img src={e?.components[0]?.example?.header_handle[0]} className="" width={170} height={90} alt="Header" /> :
             e?.components[0]?.format === 'VIDEO'? <video  src={e?.components[0]?.example?.header_handle[0]}
                                                controls
                                                width={170}
                                                height={90}
                                            /> : ''}
           </span>
           {e?.components[1]?.text && <div title={e?.components[1]?.text}>{e?.components[1]?.text.length > 15 && e?.components[1]?.text ? e?.components[1]?.text?.slice(0,14) + '...' : e?.components[1]?.text}</div>}
           </div>
           <div className="pt-2">
             {e?.components[2]?.buttons?.map((ele: any, buttonIndex: number) => (
               <p key={buttonIndex} className="quickbtns m-1 text-center" title={ele?.text}>
                  {ele?.type === 'QUICK_REPLY'? '' : ele?.type === 'PHONE_NUMBER'? <img src={phone} width={20} /> : ele?.type === 'URL'? <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1"/> : '' }  
                  {ele?.text?.length > 15? ele?.text?.slice(0,14)+'...' : ele?.text}</p>
             ))}
           </div>
         </div>
       ))}
     </div>
   </div>
   : ''}
      </div>
      <div className="buttonsDiv">
        {templateData?.map((tempDet: any, tempind: number) => {
          if (tempDet.type === "BUTTONS") {
            const numButtons = tempDet?.buttons?.length;
            const renderedButtons = tempDet?.buttons
              ?.slice(0, 2)
              .map((btn: any, innerindex: number) => (
                <span
                  key={innerindex}
                  title={btn.text}
                  className={`pt-2 mt-1 text-center singleTempBtns ${component === "chatcam"
                    ? "selectbuttons"
                    : status == -1
                      ? "failedMsgBg failedMsgBtns"
                      : "convbuttons"
                    } 
                   ${component === "conversation" ? "convButton" : ""}
                  ${numButtons === 1
                      ? "col-12 mb-2"
                      : innerindex === 2
                        ? "col-12"
                        : "singleTempBtnWidth"
                    } ${numButtons === 3 && innerindex === 0
                      ? "mr-2"
                      : numButtons === 2 && innerindex === 0
                        ? "mr-2"
                        : ""
                    } 
                                                  ${numButtons === 3 &&
                      innerindex === 2
                      ? "mb-1"
                      : ""
                    }`}
                >
                  {btn?.type?.toUpperCase() === "OTP" &&
                  btn?.otp_type?.toUpperCase() === "COPY_CODE" ?
                  <FaRegCopy /> :
                 <FontAwesomeIcon icon={faReply} className="mr-1"/> } {numButtons === 1 && innerindex === 0
                    ? sliceText(btn.text, 30)
                    : numButtons === 3 && innerindex === 2
                      ? sliceText(btn.text, 30)
                      : sliceText(btn.text, 30)}

                  {/* {sliceText(btn.text, 14)} */}
                </span>
              ));
            return (
              <div
                className={
                  status === -1 ? "failedReplyBtnsColor" : "replyBtnsColor"
                }
                key={tempind}
              >
                {renderedButtons}
                {numButtons > 2 && (
                  <span
                    className={`pt-2 mt-1 text-center singleTempBtns ${component === "chatcam"
                      ? "selectbuttons"
                      : status == -1
                        ? "failedMsgBg failedMsgBtns"
                        : "convbuttons"
                      } singleTempBtnWidth`}
                  >
                    <FontAwesomeIcon
                      icon={faList}
                      widths={80}
                      className="me-2"
                    ></FontAwesomeIcon>
                    Explore More
                  </span>
                )}
              </div>
            );
          }
          return null; // handle other cases or return null for unrecognized types
        })}
      </div>
    </div>
  );
};

export default SingleTemplate;
