import { useEffect, useRef, useState, createContext, useContext } from 'react';
import { Form, Offcanvas, Button, Spinner, FormControl } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import { QueryClient, useQuery } from '@tanstack/react-query';
import Select, { Props } from 'react-select';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import { useSelector } from "react-redux";
import {
    ContactsGetAll,
    previewTemplate,
  } from "../../../../services/campaignService";
import styles from './sendTemplate.module.scss';
import { IEditorProps } from './types';
import mainState from '../../../../redux/store/store';
import { useElmentEditor } from './hooks';
import EditorCaption from './editorCaption';
import templateIcon from '../../../assets/icons/template.svg';
import axios from '../../../../utils/axios';
import scanForVariables from '../../../utils/scanForVariables';
import { useAppSelector } from '../../../hooks';
import { isValidLink } from './fileInput';
import TemplateAdvancedSettings from './templateAdvancedsettings';
import { toast } from '../../../../common/alert';

interface ITemplateHeaderComponent {
    type: 'HEADER';
    text: string;
    format: 'TEXT' | 'VIDEO' | 'IMAGE';
    example?: {
        header_handle: string[];
    };
};

interface ITemplateFooterComponent {
    type: 'FOOTER';
    text: string;
};

interface ITemplateBodyComponent {
    type: 'BODY';
    text: string;
    example: {
        body_text: [string[]];
    };
};

interface ITemplateButtonsComponent_Button {
    text: string;
    type: 'QUICK_REPLY' | 'BUTTONS' | 'URL';
    example?: string[];
    url?: string;
};

interface ITemplateButtonsComponent {
    type: 'BUTTONS';
    buttons: ITemplateButtonsComponent_Button[];
};

export type ITemplateComponent = ITemplateBodyComponent | ITemplateHeaderComponent | ITemplateFooterComponent | ITemplateButtonsComponent;

interface ITemplate {
    id: string;
    name: string;
    language: string;
    category: string;
    templateStatus: 'APPROVED' | 'DRAFT' | 'REJECTED';
    components: ITemplateComponent[];
    media: null | {
        id: number;
        fileName: string;
        fileSize: number;
        mediaType: string;
        url: string;
    };
};

interface SelectTemplateProps {
    value: null | ITemplate;
    isInvalid: boolean;
    isValid: boolean;
};

/**
 * Error Context
 */

interface ErrorContextType {
    error: { [key: string]: boolean };
    setError: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
}
const Community = (props: Props) => {
    const businessId = useSelector(
      (state: any) => state.cartreducer.business?.business?.uid
    );
    const channelData = useSelector((state: any) => state.cartreducer.channelUid);
    const [getContactData, setContactData] = useState([]);
    const [getContactCount, setContactCount] = useState(0);
    const currentPage = useRef(0);
    const paginationLimit = useRef(10);
    const [searchPgNo, setsearchPgNo] = useState(0);
    const [searchTerm, setSearchTerm] = useState<string | null>();
    const searchContacts = async () => {};
  const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm?.length > 0 ? searchTerm : null;
    setSearchTerm(searchTermCheck);
  const getAllContacts = async () => {
    const current = currentPage.current;
    const limit = paginationLimit.current;
    const page = current > 0 ? current - 1 : 0; // Adjusting for zero-based index if needed
    const ContactList = {
      uid: businessId,
      limit: limit,
      page: page,
      search: searchTerm,
    };
    const response = await ContactsGetAll(ContactList as any);
    if (response) {
      setContactData(response.data.list);
      searchTerm != null
        ? setContactCount(response.data.list?.length)
        : setContactCount(response.data.count);
    }
  };
};


}
const errorCtx = createContext<ErrorContextType>({
    error: {},
    setError() { }
});



interface Value {
    value: string;
    label: string;
}


const SelectTemplate: React.FC<SelectTemplateProps> = props => {
    const [templates, setTemplates] = useState<Value[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const currentPage = useRef(0);
    const [hasMore, setHasMore] = useState(true);
    const [filteredData, setFilterData] = useState<any>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
    const channelId = useAppSelector(state => state.meta.channelUid);
    const limit = 10;
    const formik = useFormikContext();
    const debounceTimeout = useRef<number | null>(null);
    const [page, setPage] = useState(0);

    useEffect(() => {
        fetchData('', 0);
    }, []);

    useEffect(()=> {
        if(!selectedTemplate && props?.value?.name) {
            setSelectedTemplate({label: props?.value?.name, value: props?.value?.id});
        }
    }, [props?.value?.name])

    const fetchData = async (searchTerm: any, page: any) => {
        setIsLoading(true);
        try {
            const payload = {
                channelId,
                limit,
                page,
                search: searchTerm,
                types: ['APPROVED']
            };
            const response = await axios.post('/template/getAllTemplates', payload);  
            setFilterData(response?.data?.templates);
            const templateData = response?.data?.templates?.map((item: any) => ({
                value: item.id,
                label: item.name
            }));
            if(page!==0) {
               setTemplates(prevTemplates => [...new Set([...prevTemplates, ...templateData])]);
            }
            else {
                setTemplates(templateData)
            }
            if (response?.data?.length < 10) {
                setHasMore(false);
              } else {
                setHasMore(true);
              }
        } catch (err) {
            console.error('Error Fetching Data', err);
            setHasMore(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectTemplateChange = async (selected: any) => {
        setSelectedTemplate(selected);
        const templateId = selected.value;
        const template: ITemplate = filteredData.find((template: ITemplate) => template.id === templateId);
        if (template) {
            formik.setFieldValue('template', template);
            formik.setFieldValue('templateNodes', null);
            formik.setFieldValue('media', null);
        }
        else {
            const newTemplate = await previewTemplate(templateId, channelId);
            formik.setFieldValue('template', newTemplate);
            formik.setFieldValue('templateNodes', null);
            formik.setFieldValue('media', null);
        }
    };

    const handleInputChange = (inputValue: any) => {
        setSearchTerm(inputValue);
       
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = window.setTimeout(() => {
            fetchData(inputValue, currentPage.current); 
        }, 500);
    };

    return (
        <>
            <Form.Group className="mb-3">
                <Select
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                        menu: base => ({ ...base, width: 400 })
                    }}
                    options={templates}
                    value={selectedTemplate}
                    onChange={handleSelectTemplateChange}
                    onMenuScrollToBottom={() => {
                        if (!isLoading && hasMore) {
                            setPage((prevPage) => prevPage + 1);
                            fetchData(searchTerm , page +1);
                        }
                    }}
                    onInputChange={handleInputChange}
                />
            </Form.Group>
            <TemplateAdvancedSettings />
        </>
    );
};
/**
 * Template media component editor
 */
interface TemplateHeaderComponentInputProps {
    variables: string[];
    index: number;
    value: ITemplateHeaderComponent;
    template: any;
    media: any;
};

const TemplateHeaderComponentInput: React.FC<TemplateHeaderComponentInputProps> = props => {
    const formik = useFormikContext();
    const { error, setError } = useContext(errorCtx);
    const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
    const fileInputRef = useRef<{ current: HTMLInputElement & { index: number }; }['current']>(null);
    const currentChannelUId = useAppSelector((state) => state.meta.channelUid);

    async function handleChange(index: number, value: string) {
        const fieldName = `template.components[${props.index}]?.example?.header_handle[${index}]`;
        await formik.setFieldValue(fieldName, value);

        if (value.trim() === "" || isValidLink((props.value.format.toLocaleLowerCase() as 'image' | 'audio' | 'video' | 'document'), value)) {
            formik.setFieldError(fieldName, undefined);
            setError(prvError => {
                const updatedError = { ...prvError }
                updatedError[fieldName] = false;
                return updatedError;
            });
        } else {
            const errorMsg = "Invalid " + props.value.format.toLocaleLowerCase() + " url"
            formik.setFieldError(fieldName, errorMsg);
            setError(prvError => {
                const updatedError = { ...prvError }
                updatedError[fieldName] = true;
                return updatedError;
            });
        }
    }

    const handleUploadClick = (index: number) => {
        if (fileInputRef.current) {
            fileInputRef.current.index = index;
            fileInputRef.current.accept = getAcceptAttribute(props.value.format);
            fileInputRef.current.click();
        }
    };

    const getAcceptAttribute = (format: string): string => {
        if (format === 'VIDEO') {
            return 'video/*';
        } else if (format === 'IMAGE') {
            return 'image/*';
        }
        return '*';
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!fileInputRef.current) return;

        if((e.target.files as any)?.length > 0 && (e.target.files as any)[0]?.size >  5,242,880) {
            toast('error', 'Please upload files under 5MB');
            return;
        }

        const index = fileInputRef.current.index;
        setLoading(prvLoading => {
            const updatedLoading = { ...prvLoading }
            updatedLoading["" + index] = true;
            return updatedLoading;
        });

        const files = e.target.files;
        if (!files || files?.length === 0) return;

        const file = files[0];
        const formData = new FormData();
        formData.append('content', file);

        try {
            const response = await axios.post(
                '/media/saveMessageMedia?uid=' + currentChannelUId,
                formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setLoading(prvLoading => {
                const updatedLoading = { ...prvLoading }
                updatedLoading["" + index] = false;
                return updatedLoading;
            });
            if (typeof response.data.url === 'string') {
                // handleChange(index, response.data.url);
                formik.setFieldValue('media', response.data);
            }
        } catch (error) {
            console.error('Upload failed:', error);
            toast("error", "Failed to upload!");
        }
    };


    return (
        <Form.Group className='mb-3'>
            <Form.Label><b>Header Variables</b></Form.Label>
            {props.variables.map((mediaUrl: string, i: number) => {
                // const fieldName = `template.components[${props.index}].example.header_handle[${i}]`;
                const value = props.media ? props.media.url : props.template.media ? props.template.media.url : mediaUrl;
                const fieldName = `template.media.url`;
                const isError = fieldName in error && error[fieldName];
                const isLoading = i + "" in loading ? loading[i + ""] : false;
                return (
                    <Form.Group className='mb-3' key={`TempleteHeaderCmp-${props.index}-${i}`}>
                        <Form.Label>Media  <span className='dimmed'>(optional)</span></Form.Label>
                        {isLoading ? <div><Spinner /></div> : (
                            <>
                                <Button size='sm' variant='outline-primary' style={{ marginLeft: 12 }} onClick={() => handleUploadClick(i)}>Upload</Button>
                                <input
                                    type='file'
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                                <FormControl
                                    as='input'
                                    name={fieldName}
                                    placeholder='Enter a media URL'
                                    value={value}
                                    isInvalid={isError}
                                    onChange={(e: any) => handleChange(i, e.target.value)}
                                />
                                {isError ? (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        Invalid {props.value.format.toLowerCase()} url
                                    </div>
                                ) : null}
                                <div className='dimmed'>(
                                    You can send only these type of media:
                                    {props.value.format === 'VIDEO' && '.mp4, .3gpp'}
                                    {props.value.format === 'IMAGE' && '.jpg, .png'}
                                    )</div>
                            </>
                        )}
                    </Form.Group>
                );
            })}
        </Form.Group>
    );
}

/**
 * Template header component editor
 */
interface TemplateBodyComponentInputProps {
    variables: string[];
    index: number;
    value: ITemplateBodyComponent;
    templateId: string;
};

const TemplateBodyComponentInput: React.FC<TemplateBodyComponentInputProps> = props => {
    const formik = useFormikContext();
    const queryClient = new QueryClient();
    const { error, setError } = useContext(errorCtx);
    const [template, setTemplate] = useState<ITemplate | null>(null);
    useEffect(() => {
        queryClient.ensureQueryData({
            queryKey: ['templates'],
            queryFn: async () => {
                const channelId = mainState.getState().cartreducer.channelUid.value;
                if (!channelId) {
                    return new Promise(() => { });
                }
                try {
                    const response = await axios.request({
                        method: 'POST',
                        url: '/template/getAllTemplates',
                        data: JSON.stringify({
                            channelId,
                            page: 0,
                            limit: 5000,
                        })
                    });
                    const templates = response.data?.templates?.filter((template: any) => template.templateStatus === 'APPROVED');
                    return templates;
                } catch (error) {
                    console.error(error);
                    return [];
                }
            }
        }).then(templateList => {
            const template: ITemplate = templateList.find((temp: ITemplate) => temp.id === props.templateId);
            if (template && template.components[props.index].type === 'BODY') {
                setTemplate(template);
            }
        });
    }, []);


    function handleChange(index: number, value: string) {
        const fieldName = `template.components[${props.index}].example.body_text[0][${index}]`;
        formik.setFieldValue(fieldName, value);

        if (value.trim() !== "") {
            formik.setFieldError(fieldName, undefined);
            setError(prvError => {
                const updatedError = { ...prvError }
                updatedError[fieldName] = false;
                return updatedError;
            });
        } else {
            formik.setFieldError(fieldName, "Variable required");
            setError(prvError => {
                const updatedError = { ...prvError }
                updatedError[fieldName] = true;
                return updatedError;
            });
        }
    }

    if (!template) {
        return <Spinner />;
    }

    return (
        <Form.Group className='mb-3'>
            <Form.Label><b>Body Variables</b></Form.Label>
            {props.variables.map((variableName: string, i: number) => {
                const fieldName = `template.components[${props.index}].example.body_text[0][${i}]`;
                const isError = fieldName in error && error[fieldName];
                return (
                    <Form.Group className='mb-3' key={`TempleteBodyCmp-${props.index}-${variableName}`}>
                        <Form.Label>{variableName}<span className="required"></span></Form.Label>
                        <Form.Control
                            name={fieldName}
                            placeholder={(template.components[props.index] as ITemplateBodyComponent).example.body_text[0][i]}
                            value={props.value.example.body_text[0][i]}
                            onChange={(e: any) => handleChange(i, e.target.value)}
                            isInvalid={isError}
                        />
                        {isError ? (
                            <div className="invalid-feedback" style={{ display: "block" }}>
                                {variableName} is required
                            </div>
                        ) : null}
                    </Form.Group>
                );
            })}
        </Form.Group>
    )
}

/**
 * Template buttons component editor
 */
interface TemplateButtonComponentInputProps {
    variables: ITemplateButtonsComponent_Button[];
    index: number;
    value: ITemplateButtonsComponent;
};

const TemplateButtonComponentInput: React.FC<TemplateButtonComponentInputProps> = props => {
    const formik = useFormikContext();
    const shouldShowLabel = true;
    const { error, setError } = useContext(errorCtx);

    return (
        <Form.Group className='mb-3'>
            {shouldShowLabel && <Form.Label><b>Button Variables</b></Form.Label>}
            <div>
                {props.variables.map((button: ITemplateButtonsComponent_Button, i: number) => {
                    if (!button.example || !button.url) {
                        return (
                            <div className={styles.replyButton} key={`TempleteButtonCmp-${props.index}-${i}-`}>
                                {button.text}
                            </div>
                        )
                    };
                    const variables = scanForVariables(button.url);

                    return variables.map((variableName, j) => {
                        function handleChange(index: number, value: string) {
                            const fieldName = `template.components[${props.index}].buttons[${index}].example[${j}]`;
                            formik.setFieldValue(fieldName, value);

                            if (value.trim() !== "") {
                                formik.setFieldError(fieldName, undefined);
                                setError(prvError => {
                                    const updatedError = { ...prvError }
                                    updatedError[fieldName] = false;
                                    return updatedError;
                                });
                            } else {
                                formik.setFieldError(fieldName, "Variable required");
                                setError(prvError => {
                                    const updatedError = { ...prvError }
                                    updatedError[fieldName] = true;
                                    return updatedError;
                                });
                            }
                        }
                        const fieldName = `template.components[${props.index}].buttons[${i}].example[${j}]`;
                        const isError = fieldName in error && error[fieldName];


                        return (
                            <Form.Group className='mt-3' key={`TempleteButtonCmp-${props.index}-${i}-${j}`}>
                                <Form.Label>{variableName} <span className='required'></span></Form.Label>
                                <Form.Control
                                    name={`template.components[${props.index}].buttons[${i}].example[${j}]`}
                                    placeholder='Enter a value'
                                    value={(props.value.buttons[i].example as string[])[j]}
                                    onChange={(e: any) => handleChange(i, e.target.value)}
                                    isInvalid={isError}

                                />
                                {isError ? (
                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                        {variableName} is required
                                    </div>
                                ) : null}
                            </Form.Group>
                        );
                    });
                })}
            </div>
        </Form.Group>
    )
}


/**
 * Main editor
 */

export interface FormData {
    templateStatus: boolean;
    template: ITemplate | null;
    templateNodes: null | { [key: string]: string };
    media: any;
};

const SendTemplate: React.FC<IEditorProps> = (props: IEditorProps) => {
    const [error, setError] = useState<{ [key: string]: boolean }>({});
    const [formData, setFormData] = useState<FormData>({
        templateStatus: true,
        template: null,
        templateNodes: null,
        media: null
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'send_wa_template',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const schema = yup.object().shape({
        templateStatus: yup.boolean(),
        template: yup.object().required('Template is required')
    });


    function formSubmitHandler(data: any) {
        if (Object.values(error).every(value => !value) || Object.keys(error)?.length === 0) {
            saveElementChanges(data);
        }
    }

    return (
        <errorCtx.Provider
            value={{ error, setError }}
        >
            <Formik
                validationSchema={schema}
                onSubmit={formSubmitHandler}
                initialValues={formData}
            >
                {({ handleSubmit, values, touched, errors, setValues }) => {
                    useEffect(() => {
                        setValues(formData);
                    }, [formData, setValues]);
                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <EditorCaption onHide={props.onClose} caption='Send Template' icon={<img style={{ width: 16 }} alt='' src={templateIcon} />} />
                            <Offcanvas.Body>
                                <Form.Group className='mb-3'>
                                    <Form.Label><b>Template <span className='required'></span></b></Form.Label>
                                    <SelectTemplate
                                        value={values.template}
                                        isInvalid={(touched.template && errors.template) ? true : false}
                                        isValid={(touched.template && !errors.template) ? true : false}
                                    />
                                    {(touched.template && errors.template) ? (
                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            Template is required
                                        </div>
                                    ) : null}
                                </Form.Group>

                                {values.template ? values.template.components.map((cmp: ITemplateComponent, i: number) => {
                                    switch (cmp.type) {
                                        case 'BODY':
                                            var variables = scanForVariables(cmp.text);
                                            if (variables?.length > 0 && values?.template) {
                                                return (
                                                    <TemplateBodyComponentInput
                                                        key={`TemplateComponent-${i}`}
                                                        variables={variables}
                                                        index={i}
                                                        value={values.template?.components[i] as ITemplateBodyComponent}
                                                        templateId={values.template?.id}
                                                    />
                                                );
                                            }
                                            return null;
                                        case 'BUTTONS':
                                            if (cmp.buttons?.length > 0) {
                                                return (
                                                    <TemplateButtonComponentInput
                                                        key={`TemplateComponent-${i}`}
                                                        variables={cmp.buttons}
                                                        index={i}
                                                        value={values.template?.components[i] as ITemplateButtonsComponent}
                                                    />
                                                );
                                            }
                                            return null;
                                        case 'HEADER':
                                            if (cmp.format !== 'TEXT' && cmp?.example && cmp?.example?.header_handle?.length > 0) {
                                                return (
                                                    <TemplateHeaderComponentInput
                                                        key={`TemplateComponent-${i}`}
                                                        variables={cmp?.example?.header_handle}
                                                        index={i}
                                                        value={values.template?.components[i] as ITemplateHeaderComponent}
                                                        template={values.template}
                                                        media={values.media}
                                                    />
                                                );
                                            }
                                            return null;
                                        default:
                                            return null;
                                    }
                                }) : null}
                            </Offcanvas.Body>
                            <div className="editor-footer">
                                <Button variant='outline-dark' onClick={props.onClose}>
                                    Cancel
                                </Button>
                                <Button className='sendButton mb-1' type='submit'>
                                    Save
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </errorCtx.Provider>
    );
}

export default SendTemplate;