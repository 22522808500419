import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import { deleteApiKey, getAllApiKey } from "../../services/Apiservice";
import './APIKey.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import AddApiKey from "../ApiKey/AddApiKey";
import RightSidebar from "../../common/rightSidebar";
import SearchInput from "../../common/searchComponent";
import { sweetalert, toast } from "../../common/alert";
import BizTable from "../../common/BizTable";
import TooltipReference from "../../common/tooltip";
import { sliceText } from "../../common/sliceText";
import { ErrorImage } from "../../common/ErrorMessage";


const ApiKey = () => {

  const [allData, setAlldata] = useState<any>([]);
  const channel: any = useSelector((state: any) => state.cartreducer.channelUid);
  const [copyLinkTooltip, setCopyLinkTooltip] = useState("Copy Link");
  const [showContent, setShowContent] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editName, setEditName] = useState("");
  const [key, setKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiKeyObject, setApiKeyObject] = useState<any>(null);
  const [orgData, setOrg] = useState<any>([]);


  const nameData = (cell: any, row: any) => {
    return (
      <div>
        {cell.length > 11 ? (
          <TooltipReference
            placement="right"
            content={cell}
            tooltipId={`nameData_${row.id}`}
          >
            {sliceText(cell, 40)}
          </TooltipReference>
        ) : (
          <span>{sliceText(cell, 40)}</span>
        )}
      </div>
    );
  };


  const fetchData = useCallback(async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {


    const response = await getAllApiKey(channel?.channelAllData?.business?.uid);
    const data = response.reverse();
    if (data.length > 0) {
      setAlldata(data);
      setOrg(data);
      setIsLoading(false);
      return { data: data, totalPages: 100 }
    }
    else {
      return { data: [], totalPages: 0 }
    }
  }, []);



  const renderEditColumn = (cell: any, row: any) => {
    return (
      <div className='d-flex justify-content-center '>
        <svg
          onClick={() => handleEdit(row, row.name)}
          className='me-3'
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          onClick={() => handleDelete(row.id, row.apiKey)}
          className=""
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4H3.33333H14"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.66602 7.3335V11.3335"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.33398 7.3335V11.3335"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    );
  };


  const columns = [
    {
      Header: (
        <input
          type="checkbox"
          hidden={true}
        // checked={this.state.allChecked}
        // onChange={this.toggleAllCheckboxes}
        />
      ),
      accessor: "checkbox",
      // Cell: this.checkboxCellRenderer,
      sticky: "left",
    },
    {
      Header: "Name",
      accessor: "name",
      sticky: "left",
      Cell: ({ cell, row }: any) => nameData(cell.value, row.original),
    },
    {
      Header: "API Key",
      accessor: "apiKey",
      Cell: ({ cell, row }: any) => <CustomApiSecretCell cellText={cell.value} />
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ cell, row }: any) => renderEditColumn(cell.value, row.original),
    }
  ] as any;


  const searchKey = (searchTerm: any) => {
    if (!searchTerm) {
      setAlldata(orgData);
      return;
    }
    const searchTermCheck = searchTerm.length > 0 ? searchTerm.toLowerCase() : null;

    if (allData) {
      const filteredData = allData.filter((item: any) =>
        item.name.toLowerCase().includes(key.toLowerCase())
      );

      setAlldata(filteredData);

    } else {
      setAlldata(orgData)
    }
  }


  const getAllData = async () => {
    setIsLoading(true);
    try {
      const response = await getAllApiKey(channel?.channelAllData?.business?.uid);
      const data = response.reverse();
      setAlldata(data);
      setOrg(data);
      setIsLoading(false);
    } catch (error) {
      setAlldata('');
      setOrg('');
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopyLinkTooltip("✔ Copied");
  };

  const handleCancel = () => {
    setShowContent(false);
  };

  const createApi = () => {
    setEditName("");
    setShowContent(true);
    setShowEdit(false);
    setApiKeyObject(null);
  }

  const handleEdit = (object: any, name: string) => {
    setShowContent(true);
    setShowEdit(true);
    setEditName(name);
    setApiKeyObject(object);
  };

  const onSearchChange = (searchTerm: string) => {
    setKey(searchTerm)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      searchKey(key);
    }, 500);
    return () => clearTimeout(timer);
  }, [key, orgData]);

  const handleDelete = (id: number, apiKey: string) => {
    sweetalert(
      'warning',
      'Are you sure you want to delete?',
      async () => {
        try {
          await deleteApiKey({ id, apiKey });
          await getAllData();
          toast('success', 'Deleted successfully');
        } catch (error) {
          console.error("Error deleting API key:", error);
          toast('error', 'Error deleting API key');
        }
      },
      () => {
        // Handle cancel action here if needed
      }
    );
  };

  const CustomApiSecretCell = (cell: any) => (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="password"
          disabled
          value={cell.cellText}
          readOnly
          style={{ border: "none", background: "transparent", textAlign: "left" }}
        />
        <OverlayTrigger
          overlay={<Tooltip>{copyLinkTooltip}</Tooltip>}
          placement="bottom"
          onToggle={(isOpen) => {
            if (!isOpen) {
              setTimeout(() => {
                setCopyLinkTooltip("Copy Link");
              }, 100);
            }
          }} >
          <div className='cursor-pointer' onClick={() => copyToClipboard(cell.cellText)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 11 13" fill="none">
              <path d="M1.37891 8.23376V1.63684C1.37891 1.02961 1.84711 0.537354 2.42467 0.537354H7.65348M4.51619 11.5322H9.22212C9.7997 11.5322 10.2679 11.04 10.2679 10.4327V3.83582C10.2679 3.22859 9.7997 2.73633 9.22212 2.73633H4.51619C3.93863 2.73633 3.47043 3.22859 3.47043 3.83582V10.4327C3.47043 11.04 3.93863 11.5322 4.51619 11.5322Z" stroke="#2F3652" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </OverlayTrigger>
      </div>
    </div>

  );
  return (
    <div>
      <Row className="pt-4 pb-2" >
        <Col md="10">
          <h5 className="">API Keys</h5>
        </Col>
      </Row>
      <Row className="pt-3 pb-2">
        <Col md={3}>
          <SearchInput onSearchChange={onSearchChange} component="api" placeHolder={"Search API Name"} />
        </Col>
        <Col></Col>
        <Col md={2}>
          <Button
            variant=""
            type="button"
            onClick={createApi}
            className="sendButton w-100"
          // style={{ width: "150px", height: "40px", marginBottom: "20px" }}
          >
            <FontAwesomeIcon icon={faPlus} /> Add New API Key
          </Button>
        </Col>
      </Row>
      {showContent && (
        <RightSidebar handleCancel={handleCancel} title={!showEdit ? "Add API Key" : "Edit API Key"}>
          <AddApiKey handleCancel={handleCancel} fetchContacts={getAllData} apiObject={apiKeyObject} editName={editName} setEditName={setEditName} />
        </RightSidebar>
      )}
      <div>
        {isLoading ? (
          <div className="centerItemsWithWidth">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <BizTable
              columns={columns}
              counts={0}
              refetchData={allData}
              tableName='APIKEY'
            />
            {allData?.length > 0 && !isLoading ? '' : <ErrorImage />}
          </>
          // <BootstrapTable keyField="id" data={filteredData.sort((a: { id: number; }, b: { id: number; }) => b.id - a.id)} trClassName="table"
          //   pagination={filteredData?.length > 10}
          // >
          //   <TableHeaderColumn
          //     dataField="name"
          //     dataAlign="left"
          //     width="50"
          //     dataFormat={(cell: any, row: any) => {
          //       if (cell.length > 25) {
          //         return (
          //           <OverlayTrigger
          //             placement="bottom-end"
          //             overlay={
          //               <Tooltip id={`tooltip-${row.id}`}>
          //                 {cell}
          //               </Tooltip>
          //             }
          //           >
          //             <div style={{ cursor: 'pointer' }}>
          //               {cell.substring(0, 25)}...
          //             </div>
          //           </OverlayTrigger>
          //         );
          //       } else {
          //         return <div>{cell}</div>;
          //       }
          //     }}
          //   >
          //     <b> NAME </b>
          //   </TableHeaderColumn>

          //   <TableHeaderColumn
          //     dataField="apiKey"
          //     dataAlign="center"
          //     width="65"
          //     dataFormat={(cell: any, row: any) => (
          //       <CustomApiSecretCell cellText={cell} />
          //     )}
          //   >
          //     <b>  API KEY </b>
          //   </TableHeaderColumn>

          //   <TableHeaderColumn
          //     dataField="actions"
          //     dataAlign="center"
          //     width="80"
          //     dataFormat={(_, row: any) => (
          //       <div style={{ display: "flex", justifyContent: "center", marginLeft: "40px" }} >
          //         {/* <Link to={`/ApiKey/${row.apiKey}`} className="ml-5">
          //           <FontAwesomeIcon icon={faCog} color="#666E7D" />
          //         </Link> */}

          //         <div onClick={
          //           () => handleEdit(row.name)}>
          //           <svg
          //             width="16"
          //             height="16"
          //             viewBox="0 0 16 16"
          //             fill="none"
          //             xmlns="http://www.w3.org/2000/svg"
          //           >
          //             <path
          //               d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
          //               stroke="#666E7D"
          //               stroke-width="1.25"
          //               stroke-linecap="round"
          //               stroke-linejoin="round"
          //             />
          //             <path
          //               d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
          //               stroke="#666E7D"
          //               stroke-width="1.25"
          //               stroke-linecap="round"
          //               stroke-linejoin="round"
          //             />
          //           </svg>
          //         </div>
          //         <div onClick={
          //           () => handleDelete(row.id, row.apiKey)} className="mr-5">
          //           <svg
          //             width="16"
          //             height="16"
          //             viewBox="0 0 16 16"
          //             fill="none"
          //             xmlns="http://www.w3.org/2000/svg"
          //           >
          //             <path
          //               d="M2 4H3.33333H14"
          //               stroke="#666E7D"
          //               stroke-width="1.25"
          //               stroke-linecap="round"
          //               stroke-linejoin="round"
          //             />
          //             <path
          //               d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
          //               stroke="#666E7D"
          //               stroke-width="1.25"
          //               stroke-linecap="round"
          //               stroke-linejoin="round"
          //             />
          //             <path
          //               d="M6.66602 7.3335V11.3335"
          //               stroke="#666E7D"
          //               stroke-width="1.25"
          //               stroke-linecap="round"
          //               stroke-linejoin="round"
          //             />
          //             <path
          //               d="M9.33398 7.3335V11.3335"
          //               stroke="#666E7D"
          //               stroke-width="1.25"
          //               stroke-linecap="round"
          //               stroke-linejoin="round"
          //             />
          //           </svg>
          //         </div>
          //       </div>
          //     )}
          //   >
          //     <b> ACTION </b>
          //   </TableHeaderColumn>
          // </BootstrapTable>
        )}

      </div>

    </div>
  );
};

export default ApiKey;
