import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form, Offcanvas, FormCheck,FormControl} from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'

import { IIntegrationApp } from '../../../../entity/integration/integrationApp'
import { ACTION_LIST, ActionsListItem, IIntegrationActionListId } from '../../../../entity/integration/actionList'
import ActionsField, { IActionsField } from '../../../../entity/integration/actionsField'
import { useElmentEditor } from '../hooks'
import { IEditorProps } from '../types'
import { Label } from 'reactstrap'
import EditorCaption from '../editorCaption'
import { hubspotIcon } from '../../../../icons'
import IntegrationConnection from './integrationConnection'
import SelectIntegrationAction from './selectIntegrationAction'
import ActionsFieldRenderer from './actionsFieldRenderer'
import AddVariable from '../addVariable';
import bg from '../catalogue/sendProducts.module.scss';

type Payload = Record<string, string> | null

interface FormData {
    integrationData: {
        app: IIntegrationApp,
        connectionId: number | null
        action: IIntegrationActionListId | null
        payload: Payload,
        query: null,
        recordId: null
    },
    isSameFlow: boolean
}

function Hubspot(props: IEditorProps) {
    const [acceptPartial, setAcceptPartial] = useState(false)
    const [isCreateScreen, setIsCreateScreen] = useState(false);
    const [isEditScreen, setEditScreen] = useState(true);
    const [action, setAction] = useState<IIntegrationActionListId | null>(null)

    
    const [selectedAction, setSelectedAction] = useState<IIntegrationActionListId | null>(null)
    const [formData, setFormData] = useState<FormData>({
        integrationData: {
            app: 'Hubspot',
            connectionId: null,
            action: null,
            payload: null,
            query: null,
            recordId: null
        },
        isSameFlow: false
    })
    const { init, saveElementChanges } = useElmentEditor({
        type: 'integration/Hubspot',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

  
    const storageVariableSchema = yup.object().shape({
        name: yup.string(),
    });

    const getPayloadObjectShape = () => {
        const forEachFieldHandler = (field: ActionsField) => {
          resultShape[field.id] = (field.required ? yup.string().required(field.label + ' is required')
            : yup.string()) as yup.StringSchema<string>
        }
        const resultShape: Record<string, yup.StringSchema<string>> = {}
        // if (selectedAction && (selectedAction === 'dynamicModule' || selectedAction === 'getARecord')) {
        //   resultShape['module'] = yup.string().required('Module is required') as yup.StringSchema<string>
        //   if (zohoModuleFieldsQuery.isFetched && !zohoModuleFieldsQuery.isError && selectedAction !== 'getARecord') {
        //     const myFields = zohoModuleFieldsQuery.data?.map((field: IActionsField) => new ActionsField(field))
        //     myFields?.forEach(forEachFieldHandler)
        //   }
        // } else 
        if ((window as any).actionFields) {
          (window as any).actionFields.forEach(forEachFieldHandler)
        }
        console.log(resultShape)
        return resultShape
      }

    const schema = yup.object().shape({
        integrationData: yup.object().shape({
          connectionId: yup.string().required('Connection is required'),
          action: yup.string().required('Action is required'),
          payload: yup.object().shape(getPayloadObjectShape()).nullable(),
        //   recordId: (isQueryMode !== null) ? isQueryMode ? yup.string().notRequired().nullable()
        //     : yup.string().required('Record ID required').nullable() : yup.string().notRequired().nullable(),
        //   query: (action && action === 'getARecord') ?
        //     isQueryMode !== null ? isQueryMode ? yup.array().of(yup.object().shape({
        //       field: yup.string().required('Field is required'),
        //       value: yup.string().required('Value is required'),
        //     })).min(1, 'Atleast 1 query is required')
        //       : yup.array().nullable()
        //       : yup.object().nullable()
        //     : yup.array().nullable()
        }),
        
          
      })

    

    // getUserMeetings = async () => {

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, setFieldValue, values, errors, touched, setValues,handleChange }) => {

                
                console.log('errors', errors)
                const mapFieldsHandler = (field: ActionsField) => {
                    console.log(field)
                    const isError = Boolean(errors.integrationData?.payload
                      && field.id in (errors.integrationData as any).payload
                      && Boolean((errors.integrationData as any).payload[field.id]))
                    const isTouched = Boolean(touched.integrationData?.payload
                      && field.id in (touched.integrationData as any).payload
                      && Boolean((touched.integrationData as any).payload[field.id]))
                    return (
                      <Form.Group
                        key={field.id}
                        className='my-2'
                      >
                        <Label>{field.label}</Label>
                                     <FormControl
                          name={'integrationData.payload.' + field.id}
                          required={field.required as boolean} // Cast field.required to boolean
                          as='input'
                          value={values.integrationData.payload ? values.integrationData.payload[field.id] : ''}
                          isInvalid={isTouched && isError}
                          isValid={isTouched && !isError}
                          onChange={handleChange}
                        />
                        
                        {isTouched && isError ? (
                          <div className='invalid-feedback d-block'>
                            {String((errors.integrationData as any).payload[field.id])}
                          </div>
                        ) : null}
                        
                        <AddVariable 
                          static 
                          fieldName={'integrationData.payload.' + field.id} 
                          fieldValue={values.integrationData.payload ? values.integrationData.payload[field.id] : ''} 
                          limit={1024} 
                        />
                                              
                      </Form.Group>
                    )
                  }

                
                useEffect(() => {
                    setValues(formData)
                    console.log('heyy setting form data', {
                      formData,
                      props
                    });
                  }, [formData, setValues])

                const myAction: ActionsListItem | null = useMemo(() => {
                    if (values.integrationData.connectionId) {
                        (window as any).appConnectionId = values.integrationData.connectionId
                    }
                    if ('action' in values.integrationData && values.integrationData.action) {
                        return ACTION_LIST['Hubspot'].find(action => action.id === values.integrationData.action) || null
                    }
                    return null
                }, [values])
                // useEffect(() => {
                //     if (myAction && myAction.id === 'createContact'
                //         && !values.integrationData.payload
                //         && !formData.integrationData.payload) {
                //         setFieldValue('integrationData.payload', {
                            
                //                 firstname: '',
                //                 lastname: '',
                //                 email: '',
                            
                //         });
                //     }
                // }, [myAction]);

                useEffect(() => {
                    if (myAction) {
                      const isModuleRequired = (values.integrationData.action === 'dynamicModule' || values.integrationData.action === 'getARecord')
                      if (isModuleRequired && !values.integrationData.payload) {
                        setFieldValue('integrationData.payload.module', '')
                      }
                      setSelectedAction(myAction.id)
                      
                      setAction(myAction.id);
                      (window as any).actionFields = myAction.fields;
                      if (!(window as any).fieldsInit && props.create) {
                        Object.keys(getPayloadObjectShape()).forEach((name: string) => {
                          if (name !== 'module') {
                            const fieldName = `integrationData.payload.${name}`
                            setFieldValue(fieldName, '')
                          }
                        });
                       
                        (window as any).fieldsInit = true
                      }
                    }
                  }, [myAction])
               
                const onGoBackHandler = () => {
                    if (values.integrationData.action) {
                        setFieldValue('integrationData.action', null)
                        setFieldValue('integrationData.payload', null)
                    } else if (values.integrationData.connectionId) {
                        setFieldValue('integrationData.connectionId', null)
                    } else {
                        props.onClose()
                    }
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption
                            onHide={onGoBackHandler}
                            caption='Hubspot'
                            icon={<img style={{ width: 21 }}
                                alt='Hubspot'
                                src={hubspotIcon}
                            />} />
                        <Offcanvas.Body>
                        {myAction ? <h5 className='mb-3'>{myAction.label}</h5> : null}
                            {values.integrationData.connectionId ? (
                                values.integrationData.action ? (
                                    <>
                                        {myAction ?
                                            <>
                                                {myAction.fields
                                                    .filter(field => field.required)
                                                    .map(mapFieldsHandler)}
                                                {myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 && (
                                                        <>
                                                            <FormCheck
                                                                name='isSameFlow'
                                                                type='switch'
                                                                id='fields-shopify-switch'
                                                                label="Optional Fields"
                                                                checked={values.isSameFlow}
                                                                onChange={event => {
                                                                    setFieldValue('isSameFlow', event.target.checked);
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                {values?.isSameFlow && myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 ? (
                                                    <div className={bg.background}>
                                                        {myAction.fields
                                                            .filter(field => !field.required)
                                                            .map(mapFieldsHandler)}
                                                    </div>
                                                ) : null}
                                            </> : null}
                                    </>
                                ) : <SelectIntegrationAction app='Hubspot' />) : (
                                    <IntegrationConnection app='Hubspot' scope='COM'
                                    onScreenChange={(screen: any) => {setIsCreateScreen(screen === 'create'); setEditScreen(screen !== 'edit');}}
                                />
                            )}
                        </Offcanvas.Body>
                        { !isCreateScreen && isEditScreen && 
                        <div className='editor-footer'>
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button variant='primary' className='sendButton' type='submit' disabled={isCreateScreen}>
                                Save
                            </Button>
                        </div>
            }
                    </Form>
                )
            }}
        </Formik>
    )
}

export default Hubspot