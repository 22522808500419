import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Form,
  OverlayTrigger,
  Spinner,
  ToggleButton,
  Tooltip,
  TooltipProps,
} from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import * as yup from "yup";
import { ErrorMessage, Formik, useFormikContext } from "formik";

import styles from "./integrationConnection.module.scss";
import { IIntegrationApp } from "../../../../entity/integration/integrationApp";
import * as designFlowService from "../../../../services/designFlow";
import { useAppSelector } from "../../../../hooks";
import NoDataFallback from "../../../../../common/noDataFallback";
import { SERVER_URL } from "../../../../../utils/axios";
import { showAlertYesNo } from "../../../../../common/alertYesNo";
import { toast } from "../../../../../common/alert";
import { ValueSource } from "react-avatar";
import { continueWithGoogle } from "../../../../icons";
import { BsInfoCircleFill } from "react-icons/bs";
import sheets from "./googleSheets.module.scss";

type Props = {
  app: IIntegrationApp;
  scope?: "COM" | "IN";
  onScreenChange?: (screen: ScreenState) => void;
};

type ScreenState = "list" | "create" | "edit";

export interface MetaData {
  shopifyDomainUrl: string;
  sangamUrl: string;
  woocommerceUrl: string;
}

interface CreateConnectionForm {
  name: string;
  app: IIntegrationApp;
  token?: string;
  metaData?: MetaData;
  isEdit?: boolean;
  firstToken?: string;
  secondToken?: string;
}

function IntegrationConnection({ app, scope, onScreenChange }: Props) {
  const [screen, setScreen] = useState<ScreenState>("list");
  const [connection, setConnection] =
    useState<null | designFlowService.IIntegrationConnection>(null);
  const businessId = useAppSelector((state) => state.meta.businessId);
  const businessUid = useAppSelector((state) => state.meta.businessUid);
  const connectionsQuery = useQuery({
    queryKey: ["integration-connections", businessId],
    queryFn: () =>
      businessId
        ? designFlowService.getIntegrationConnections(
          app,
          businessId,
          scope || "COM"
        )
        : null,
    retry: false,
  });
  const parentFormik = useFormikContext();
  useEffect(() => {
    connectionsQuery.refetch();
  }, [scope]);
  useEffect(() => {
    if (onScreenChange) {
      onScreenChange(screen);
    }
  }, [screen, onScreenChange]);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const deleteConnectionHandler = async ({
    id,
  }: designFlowService.IIntegrationConnection) => {
    const response = await showAlertYesNo(
      "question",
      "Are you sure you want to delete this connection?"
    );
    if (response.isConfirmed) {
      try {
        await designFlowService.deleteConnection(id);
        toast("success", "Connection deleted successfully");
        connectionsQuery.refetch();
      } catch (error) {
        console.error(error);
        toast("error", "Connection failed to delete");
      }
    }
  };

  const useConnectionHandler = ({
    id, metaData
  }: designFlowService.IIntegrationConnection) => {
    parentFormik.setFieldValue("integrationData.connectionId", id);
    if (metaData?.shopifyDomainUrl) {
      parentFormik.setFieldTouched("integrationData.shopUrl", true);
      parentFormik.setFieldValue("integrationData.shopUrl", metaData.shopifyDomainUrl);
    }
  };
  const renderConnectionButtons = () => (
    <ButtonGroup className="d-flex mb-3" aria-label="Connection tabs">
      <ToggleButton
        id="toggle-list"
        type="radio"
        variant={screen === "list" ? "success" : "outline-primary"}
        className={`flex-grow-1 ${styles.smallToggle}`}
        checked={screen === "list"}
        value="list"
        onChange={(e) => setScreen(e.currentTarget.value as ScreenState)}
      >
        Select Existing Connection
      </ToggleButton>
      <ToggleButton
        id="toggle-create"
        type="radio"
        variant={screen === "create" ? "success" : "outline-primary"}
        className={`flex-grow-1 ${styles.smallToggle}`}
        checked={screen === "create"}
        value="create"
        onChange={(e) => setScreen(e.currentTarget.value as ScreenState)}
      >
        Add New Connection
      </ToggleButton>
    </ButtonGroup>
  );



  const createOrUpdateConnectionHandler = async ({
    name, token, metaData, firstToken, secondToken
  }: CreateConnectionForm) => {
    if (screen === "create") {
      let url = '';
      if (app === "Shopify" || app === "SangamCRM" || app === "Woocommerce") {
        const correctUrl = app === "Shopify" ? metaData?.shopifyDomainUrl : app === "Woocommerce" ? metaData?.woocommerceUrl : metaData?.sangamUrl;
        const tokens = app === "Woocommerce" ? `${firstToken}:${secondToken}` : token;
        url = SERVER_URL +
          "/integration/connect?scope=" +
          scope +
          "&app=" +
          app +
          "&connectionName=" +
          name +
          "&businessUid=" +
          businessUid +
          "&token=" + tokens +
          "&url=" + correctUrl
      } else {
        url = SERVER_URL +
          "/integration/connect?scope=" +
          scope +
          "&app=" +
          app +
          "&connectionName=" +
          name +
          "&businessUid=" +
          businessUid

        if (app === "OpenAI") {
          url += "&token=" + token;
        }
      }

      if (app === 'ZohoCRM' || app === 'RazorPay' || app === 'GoogleSheets' || app === 'ZohoBigin' || app === 'Zoom' || app === 'Hubspot') {
        url += '&redirectOAuth=true';
      }
      else {
        url += '&redirectOAuth=false';
      }

      const connectionWindow = window.open(url, '_blank',
        'toolbar=yes,scrollbars=yes,resizable=yes,width=800,height=600')
      const pollInterval = 1000;
      let pollingInterval: NodeJS.Timeout | undefined;

      const checkPopupClosed = () => {
        if (connectionWindow && connectionWindow.closed) {
          clearInterval(pollingInterval);
          setScreen("list");
          connectionsQuery.refetch();
        }
      };

      if (connectionWindow) {
        pollingInterval = setInterval(checkPopupClosed, pollInterval);
      }
    } else if (connection) {
      try {
        await designFlowService.updateIntegrationConnection(connection.id, {
          ...connection,
          name
        });
        connectionsQuery.refetch();
        setScreen("list");
        setConnection(null);
        toast("success", "Connection updated");
      } catch (error) {
        toast("error", "Connection failed to update");
      }
    }
  };

  if (screen === "create" || screen === "edit") {
    const connectionSchema = yup.object().shape({
      id: yup.number().notRequired(),
      name: yup.string().required("Connection name is required"),
      token: yup.string().when(['$app', '$isEdit'], {
        is: (app: string, isEdit: boolean) => !isEdit && (app === 'Shopify' || app === 'SangamCRM'),
        then: yup.string().required("API Key is required"),
        otherwise: yup.string().nullable(),
      }),
      firstToken: yup.string().when(['$app', '$isEdit'], {
        is: (app: string, isEdit: boolean) => !isEdit && (app === 'Woocommerce'),
        then: yup.string().required("Consumer Key is required"),
        otherwise: yup.string().nullable(),
      }),
      secondToken: yup.string().when(['$app', '$isEdit'], {
        is: (app: string, isEdit: boolean) => !isEdit && (app === 'Woocommerce'),
        then: yup.string().required("Consumer Secret is required"),
        otherwise: yup.string().nullable(),
      }),
      metaData: yup.object().shape({
        shopifyDomainUrl: yup.string().when(['$app', '$isEdit'], {
          is: (app: string, isEdit: boolean) => !isEdit && app === 'Shopify',
          then: yup.string()
            .required("Shopify URL is required")
            .matches(
              /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-]*)*$/,
              "Please enter a valid URL"
            ),
          otherwise: yup.string().nullable(),
        }),
        sangamUrl: yup.string().when(['$app', '$isEdit'], {
          is: (app: string, isEdit: boolean) => !isEdit && app === 'SangamCRM',
          then: yup.string()
            .required("Sangam URL is required")
            .matches(
              /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-]*)*$/,
              "Please enter a valid URL"
            ),
          otherwise: yup.string().nullable(),
        }),
        woocommerceUrl: yup.string().when(['$app', '$isEdit'], {
          is: (app: string, isEdit: boolean) => !isEdit && app === 'Woocommerce',
          then: yup.string()
            .required("WooShop Subdomain URL is required")
            .matches(
              /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-]*)*$/,
              "Please enter a valid URL"
            ),
          otherwise: yup.string().nullable(),
        })
      }),      
      isEdit: yup.boolean(),
    });

    return (
      <>

        {screen === "create" && (app === "RazorPay" || app === "GoogleSheets" || app === "Shopify" || app === "SangamCRM" || app === "Woocommerce" || app === "OpenAI" ||app === "Zoom" || app === "Hubspot")
          ? renderConnectionButtons()
          : ""}
        <h5>{screen === "create" ? "" : "Edit Connection"} </h5>
        <Formik
          validationSchema={connectionSchema}
          onSubmit={createOrUpdateConnectionHandler}
          initialValues={
            {
              name: screen === "create" ? "" : connection?.name,
              app: app,
              metaData: screen === "create" ? { shopifyDomainUrl: '', sangamUrl: '', woocommerceUrl: '' } : connection?.metaData,
              token: screen === "create" ? "" : connection?.token,
              isEdit: false,
              firstToken: '',
              secondToken: ''
            } as CreateConnectionForm
          }
        >
          {({ handleChange, values, touched, errors, submitForm, setFieldValue }) => {
            useEffect(() => {
              setFieldValue('isEdit', screen === 'edit');
            }, [screen])

            return (
              <>
                <Form.Group className="mt-2">
                  <Form.Label>
                    Connection Name <span className="required"></span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder={isFocused ? "" : "Enter Connection Name"}
                    value={values.name}
                    isInvalid={touched.name && errors.name ? true : false}
                    onChange={handleChange}
                    isValid={touched.name && !errors.name}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  {touched.name && errors.name ? (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.name}
                    </div>
                  ) : null}
                </Form.Group>

                {!values?.isEdit && (
                  <>
                    {(app === "Shopify" || app === "SangamCRM" || app === "OpenAI") && (
                      <Form.Group className="mt-3">
                        <div className='d-flex align-items-center'>
                          <Form.Label>API key <span className="required"></span></Form.Label>
                          <>
                            {app === "Shopify" && (
                              <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip> Create and install the app in Shopify to get the Admin API access token. If already installed, find it in the app's settings under 'API Credentials'
                                </Tooltip>}
                              >
                                <Button size='sm' variant='default'>
                                  <BsInfoCircleFill className={sheets.infoIcon} />
                                </Button>
                              </OverlayTrigger>
                            )}
                          </>
                        </div>
                        <Form.Control
                          type="text"
                          name="token"
                          placeholder="Enter API Key"
                          isInvalid={touched.token && errors.token ? true : false}
                          isValid={touched.token && !errors.token}
                          value={values?.token}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="token" component="div" className='invalid-feedback d-block' />
                      </Form.Group>
                    )}
                    {app === 'Woocommerce' && (
                      <>
                        <Form.Group className="mt-3">
                          <Form.Label>Consumer Key <span className="required"></span></Form.Label>
                          <Form.Control
                            type="text"
                            name="firstToken"
                            placeholder="Enter Consumer Key"
                            isInvalid={touched.firstToken && errors.firstToken ? true : false}
                            isValid={touched.firstToken && !errors.firstToken}
                            value={values?.firstToken}
                            onChange={handleChange}
                          />
                          <ErrorMessage name="firstToken" component="div" className='invalid-feedback d-block' />
                        </Form.Group>
                        <Form.Group className="mt-3">
                          <Form.Label>Consumer Secret <span className="required"></span></Form.Label>
                          <Form.Control
                            type="text"
                            name="secondToken"
                            placeholder="Enter Consumer Secret"
                            isInvalid={touched.secondToken && errors.secondToken ? true : false}
                            isValid={touched.secondToken && !errors.secondToken}
                            value={values?.secondToken}
                            onChange={handleChange}
                          />
                          <ErrorMessage name="secondToken" component="div" className='invalid-feedback d-block' />
                        </Form.Group>
                        <Form.Group className="mt-3">
                          <Form.Label>WooShop Subdomain (Ex: wooshop.example.com) <span className="required"></span></Form.Label>
                          <Form.Control
                            type="text"
                            name="metaData.woocommerceUrl"
                            placeholder="Enter WooShop Subdomain"
                            value={values?.metaData?.woocommerceUrl}
                            onChange={handleChange}
                            isValid={touched.metaData && !errors.metaData}
                            isInvalid={touched.metaData && typeof (errors?.metaData) === 'object'}
                          />
                          <ErrorMessage name="metaData.woocommerceUrl" component="div" className='invalid-feedback d-block' />
                        </Form.Group>
                      </>
                    )}
                    {values.app === "SangamCRM" && (
                      <Form.Group className="mt-3">
                        <Form.Label>Sangam URL <span className="required"></span></Form.Label>
                        <Form.Control
                          type="text"
                          name="metaData.sangamUrl"
                          placeholder="Enter Sangam URL"
                          value={values?.metaData?.sangamUrl}
                          onChange={handleChange}
                          isValid={touched.metaData && !errors.metaData}
                          isInvalid={touched.metaData && typeof (errors?.metaData) === 'object'}
                        />
                        <ErrorMessage name="metaData.sangamUrl" component="div" className='invalid-feedback d-block' />
                      </Form.Group>
                    )}

                    {values.app === "Shopify" && (
                      <Form.Group className="mt-3">
                        <Form.Label>Shopify Subdomain (Ex: example.myshopify.com) <span className="required"></span></Form.Label>
                        <Form.Control
                          type="text"
                          name="metaData.shopifyDomainUrl"
                          placeholder="Enter Shopify Subdomain"
                          value={values?.metaData?.shopifyDomainUrl}
                          onChange={handleChange}
                          isValid={touched.metaData && !errors.metaData}
                          isInvalid={touched.metaData && typeof (errors?.metaData) === 'object'}
                        />
                        <ErrorMessage name="metaData.shopifyDomainUrl" component="div" className='invalid-feedback d-block' />
                      </Form.Group>
                    )}
                  </>
                )}
                {app !== 'GoogleSheets' &&
                  <div className='editor-footer'>
                    <Button
                      type="button"
                      onClick={() => {
                        setScreen("list");
                        setConnection(null);
                      }}
                      className="cancelButton"
                      variant='outline-dark'
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      variant="primary"
                      className="mr-2 sendButton"
                      onClick={() => submitForm()}
                    >
                      {screen === "create" ? "Create" : "Update"}
                    </Button>
                  </div>
                }
                {
                  app === 'GoogleSheets' && (
                    <div className={styles.parentContainer}>
                      <img className={styles.centerScreen} onClick={() => submitForm()} src={continueWithGoogle} alt="Continue with Google" />
                    </div>
                  )
                }
              </>
            );
          }}
        </Formik>
      </>
    );
  } else {
    return (
      <Form.Group className="mb-3">
        {(app === "RazorPay" || app === 'GoogleSheets' || app === "Shopify" || app === "SangamCRM" || app === "Woocommerce" || app === "OpenAI" || app === "Zoom" || app === "Hubspot") &&
          connectionsQuery?.data &&
          connectionsQuery?.data?.length > 0
          ? renderConnectionButtons()
          : ""}
        {/* <Button variant="primary" className='d-flex' onClick={() => setScreen('create')}>Create New Connection</Button>
        <Button variant="secondary" className='d-flex'  onClick={() => setScreen('list')}>Manage Existing Connections</Button> */}
        {connectionsQuery.data && connectionsQuery.data.length > 0 ? (
          <Form.Label>
            Connection <span className="required"></span>
          </Form.Label>
        ) : null}
        {connectionsQuery.isLoading ? (
          <Spinner />
        ) : connectionsQuery.isError ? app === "Shopify" ? <Alert color="danger"> No connections Found! Please contact Support Team</Alert> : (
          <Alert color="danger">Failed to load connections</Alert>
        ) : connectionsQuery.data && connectionsQuery.data.length > 0  ? (
          connectionsQuery.data[0]?.app === app ?
          <>
            <ul className={styles.connectionList}>
              {connectionsQuery.data?.map((connection) => {
                const truncatedName = connection.name.length > 30 ? connection.name.substring(0, 30) + '...' : connection.name;
                const renderTooltip = (props: React.JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
                  <Tooltip id="name-tooltip" {...props}>
                    {connection.name}
                  </Tooltip>
                );
                return (
                  <li key={connection.id} className={styles.connectionItem}>
                    <OverlayTrigger
                      placement="top"
                      overlay={renderTooltip}
                    >
                      <div className={styles.name}>
                        {truncatedName}
                      </div>
                    </OverlayTrigger>
                    <div className={styles.action}>
                      {app !== 'GoogleSheets' && (
                        <Button
                          onClick={() => {
                            deleteConnectionHandler(connection);
                          }}
                          size="sm"
                          variant="white"
                          className="mr-2"
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          setConnection(connection);
                          setScreen("edit");
                        }}
                        size="sm"
                        variant="white"
                        className="mr-2"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={() => useConnectionHandler(connection)}
                      >
                        Connect
                      </Button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </> : <>
          <Spinner/>
          </>
        ) : (
          <NoDataFallback
            caption="No Connections Found"
            message={
              <Button
                className="mt-1 sendButton"
                onClick={() => setScreen("create")}
              >
                <FontAwesomeIcon icon={faAdd} className="mr-1" />
                Create
              </Button>
            }
          />
        )}
      </Form.Group>
    );
  }
}

IntegrationConnection.defaultProps = {
  scope: "COM",
};

export default IntegrationConnection;
