import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Handle, Position } from 'reactflow';
import Node from './node';
import { FormData as ISendTemplateFormData } from '../editor/sendTemplate';
import classes from './sendList/listItem.module.css';
import { uuidv4 } from '../../../utils/uuid';

type FormData = ISendTemplateFormData & { type: string, location: { latitude: number } }; // Add the missing 'location' property to the FormData type

const SendTemplate: React.FC<{ data: FormData, readOnly?: boolean }> = props => {
    return (
        <Node
            setTitle={function (): void {
                throw new Error('Function not implemented.');
            }} label='Send Template'
            showRight={false}
            id="someId"
            type="someType"
            {...props}
            content={(
                <>
                    {props.data.template ? (
                        <div>
                            <div>Template: {
                                props?.data?.template?.name
                                    ? props.data.template.name.length > 25
                                        ? props.data.template.name.substring(0, 25) + '...'
                                        : props.data.template.name
                                    : ''
                            }</div>
                            {/* <ListGroup className={classes.listitem}>
                                {props.data.template.components.map(cmp => {
                                    if (cmp.type === 'BUTTONS') {
                                        return cmp.buttons.map((btn, i) => {
                                            if (btn.type === 'QUICK_REPLY') {
                                                return (
                                                    <ListGroup.Item
                                                        key={`SendTemplateNode-Button-${uuidv4()}`}
                                                        className={[classes.item].join(' ')}
                                                    >
                                                        <div>
                                                            <span></span>
                                                            <span className={classes.caption}>{btn.text}</span>
                                                            {!props.readOnly ? (
                                                                <Handle
                                                                    className={classes.dot}
                                                                    id={`send_wa_template/${i}`}
                                                                    type='source'
                                                                    position={Position.Right}
                                                                    isConnectable />
                                                            ) : null}
                                                        </div>
                                                    </ListGroup.Item>
                                                );
                                            }
                                            return null;
                                        });
                                    }

                                    return null;
                                })}
                            </ListGroup> */}
                        </div>
                    ) : null}
                </>
            )} />
    );
}

export default SendTemplate;