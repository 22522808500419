import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form, FormGroup, FormText, Input, Label, Spinner, UncontrolledTooltip } from 'reactstrap'
import { Alert, Collapse, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { ErrorMessage, Formik, FormikHelpers, useFormikContext } from 'formik'
import DateRangePicker from 'react-daterange-picker'
import TimePicker from 'react-time-picker'
import Calendar from 'react-calendar'
import moment, * as originalMoment from 'moment'
import * as yup from 'yup'
import { extendMoment } from 'moment-range'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCalendarAlt, faClock, faEdit, faSquareMinus } from '@fortawesome/free-regular-svg-icons'
import { PiMinusCircleFill } from 'react-icons/pi'
import { useSelector } from 'react-redux'
import { faArrowLeft, faCheck, faClose, faInfoCircle, faPlus, faWrench } from '@fortawesome/free-solid-svg-icons'
import { Item, Menu, useContextMenu } from 'react-contexify'
import 'react-time-picker/dist/TimePicker.css'
import 'react-clock/dist/Clock.css'
import { useQuery } from '@tanstack/react-query'
import styles from './MemberCalenderForm.module.scss'
import * as appointmentService from '../../services/appointmentService'
import { uuidv4 } from '../../botFlows/utils/uuid'
import { DelayInput } from '../../botFlows/components/designFlow/editor/delayEditor'
import { toast } from '../../common/alert'
import SelectHoliday from './SelectHoliday'
import HolidayForm from './HolidayList/HolidayForm'
import DateInput from '../../common/dateInput'
import { SOMETHING_WENT_WRONG } from '../../constants/errorMessage'
import { ITemplateComponent } from '../../botFlows/components/designFlow/editor/sendTemplate'
import TemplateList from '../Template/templateList'
import Modall from '../../common/modall'
import DaySelection from '../Sequence/DaySelectionComponent'
import { an } from '@fullcalendar/core/internal-common'
import { previewTemplate } from '../../services/campaignService'
import { showAlertYesNo } from '../../common/alertYesNo'
import ChatCamTemplate from '../Template/chatCamTemplate'

const momentRange = extendMoment(originalMoment)


type ErrorProps = {
  message: string
}

function Error({ message }: ErrorProps) {
  return (
    <div className='invalid-feedback' style={{ display: 'block' }}>{message}</div>
  )
}

interface ITimeSlot {
  date: string
  startTime: string
  endTime: string
}

interface HolidayListFormData {
  name: string,
  description: string,
  list: { id: string, name: string, date: string }[]
}

export interface FormData {
  dateRange: { startDate: string, endDate: string },
  unavailableDate: { id: string, date: string }[],
  availableHour: { id: string, startTime: string, endTime: string, day: boolean[] }[],
  duration: string,
  defaultSettings: {
    enableContactReschedule: boolean
  },
  dateAvailabilityPreset: 'WORKING_DAYS_ONLY' | 'NONE',
  day: boolean[],
  excludeTimeslot: ITimeSlot[],
  bufferTime: string,
  holidayList: number,
  holidayForm: null | HolidayListFormData
  remainingTime: string,
  templateStatus: boolean;
  template: ITemplate | null;
  templateNodes: null | { [key: string]: string };
  media: any;
  startTemplateId: null | string;
  remainingTemplateId: null | string;
  endTemplateId: string;
  templateId: string;
  startTemplate: string;
  remainingTemplate: string;
  id: number;
  templateMessage: any;
  memberUid: string;
  deleteTemplateId: string;
}


type MoreSettingProps = {
  children: React.ReactElement,
  defaultOpen?: boolean,
}

function MoreSetting({ children, defaultOpen }: MoreSettingProps) {
  const [show, setShow] = useState(Boolean(defaultOpen))
  return (
    <>
      <Button
        color='link'
        onClick={() => setShow(current => !current)}
      >
        Show {show ? 'less' : 'more'} settings...
      </Button>
      <Collapse in={show} children={children} />
    </>
  )
}

MoreSetting.defaultProps = { defaultOpen: false }

type DateRangeProps = {}

function DateRange(props: DateRangeProps) {
  const formik = useFormikContext<FormData>()
  const dateRangeValue = useMemo(() => {
    return momentRange.range(
      moment(formik.values.dateRange.startDate),
      moment(formik.values.dateRange.endDate)
    )
  }, [formik.values.dateRange])

  return (
    <OverlayTrigger
      trigger='click'
      placement='bottom'
      rootClose
      overlay={(
        <Popover style={{ maxWidth: '350px', zIndex: 16000 }}>
          <DateRangePicker
            value={dateRangeValue}
            onSelect={(value: { start: originalMoment.Moment, end: originalMoment.Moment }) => {
              formik.setFieldValue('dateRange', {
                startDate: moment(value.start).format('YYYY-MM-DD'),
                endDate: moment(value.end).format('YYYY-MM-DD')
              })
            }}
            singleDateRange={true}
          />
        </Popover>
      )}>
      <div className={['form-control', styles.calenderInput].join(' ')}>
        <FontAwesomeIcon icon={faCalendar} className='mr-2' />
        <span className='value'>
          {dateRangeValue.start.format('DD/MM/YYYY')}
          {' - '}
          {dateRangeValue.end.format('DD/MM/YYYY')}
        </span>
      </div>
    </OverlayTrigger>
  )
}

interface ITemplate {
  id: string;
  name: string;
  language: string;
  category: string;
  templateStatus: 'APPROVED' | 'DRAFT' | 'REJECTED';
  components: ITemplateComponent[];
  media: null | {
    id: number;
    fileName: string;
    fileSize: number;
    mediaType: string;
    url: string;
  };
};


type UnavailableDatesProps = {}

function UnavailableDates({ }: UnavailableDatesProps) {
  const formik = useFormikContext<FormData>()

  const addDateHandler = () => {
    const updatedUnavailableDate = [...formik.values.unavailableDate]
    updatedUnavailableDate.push({
      id: uuidv4(),
      date: ''
    })
    formik.setFieldValue('unavailableDate', updatedUnavailableDate)
  }

  const removeDateHandler = (id: string) => {
    const updatedUnavailableDate = formik.values.unavailableDate.filter(date => {
      return date.id !== id
    })
    formik.setFieldValue('unavailableDate', updatedUnavailableDate)
  }

  return (
    <div className={styles.unavailableDates}>
      {formik.values.unavailableDate.map((date, index) => {
        const isTouched = Boolean(formik.touched.unavailableDate)
        return (
          <div key={date.id}>
            <div className={styles.dateItem}>
              <Button
                type='button'
                color='white'
                className={styles.removeBtn}
                onClick={() => removeDateHandler(date.id)}
              >
                <PiMinusCircleFill />
              </Button>
              <DateInput
                name={'unavailableDate[' + index + '].date'}
                value={formik.values.unavailableDate[index].date}
                minToday
                restrictPastDates
              />
            </div>
            <div className='mx-2 my-1'>
              {formik.touched.unavailableDate
                && (formik.errors as any).unavailableDate
                && (formik.errors as any).unavailableDate[index]
                && 'date' in (formik.errors as any).unavailableDate[index]
                && typeof (formik.errors as any).unavailableDate[index].date === 'string' ? (
                <Error message={(formik.errors as any).unavailableDate[index].date as string} />
              ) : null}
            </div>
          </div>
        )
      })}
      <Button
        color='primary'
        size='sm'
        className='addButton'
        onClick={addDateHandler}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </div>
  )
}

type TimeSlotsProps = {
  seltectedDays: any
  setCalendarDays?: any
}

function TimeSlots({ seltectedDays, setCalendarDays }: TimeSlotsProps) {
  const headerDays = ["M", "T", "W", "T", "F", "S", "S"];
  const headerWeekDays = ["M", "T", "W", "T", "F"];
  const formik = useFormikContext<FormData>()

  const addTimeslotHandler = () => {
    const updatedAvailableHour = [...formik.values.availableHour]
    updatedAvailableHour.push({
      id: uuidv4(),
      startTime: '',
      endTime: '',
      day: Array(headerDays.length).fill(true)
    })
    formik.setFieldValue('availableHour', updatedAvailableHour)
  }

  const removeDateHandler = (id: string) => {
    const updatedAvailableHour = formik.values.availableHour.filter(date => {
      return date.id !== id
    })
    formik.setFieldValue('availableHour', updatedAvailableHour)
  }

  const isTouched = Boolean(formik.touched.availableHour);

  useEffect(() => {
    if (formik.values.dateAvailabilityPreset === 'NONE') {
      const combinedDayArray = formik.values.availableHour.reduce((acc, current) => {
        return acc.map((val, index) => val || current.day[index]);
      }, new Array(7).fill(false));

      setCalendarDays(combinedDayArray);
    } else {
      const combinedDayArray = formik.values.availableHour.reduce((acc, current) => {
        return acc.map((val, index) => val || current.day[index]);
      }, new Array(5).fill(false));

      setCalendarDays(combinedDayArray);
    }

  }, [formik.values.availableHour, formik.values.dateAvailabilityPreset])


  return (
    <div className={styles.timeslots}>
      {formik.values.availableHour.map((timeSlot, index) => {
        const isError = isTouched && formik.errors.availableHour && formik.errors.availableHour[index]
        const fieldBaseName = 'availableHour[' + index + ']'

        return (
          <div key={timeSlot.id}>
            <div className={styles.timeslotItem}>
              <Button
                type='button'
                color='white'
                className={styles.removeBtn}
                onClick={() => removeDateHandler(timeSlot.id)}
              >
                <PiMinusCircleFill />
              </Button>
              <span className={styles.timeslotItem__text}>From</span>
              <div className={['form-control', styles.calenderInput].join(' ')}>
                <FontAwesomeIcon icon={faClock} className='mr-2' />
                <TimePicker
                  clockIcon={null}
                  clearIcon={null}
                  // format="HH:mm"
                  value={timeSlot?.startTime}
                  onChange={value => {
                    formik.setFieldValue(fieldBaseName + '.startTime', value + ':00')
                  }}
                />
              </div>
              <span className={styles.timeslotItem__text}>To</span>
              <div className={['form-control', styles.calenderInput].join(' ')}>
                <FontAwesomeIcon icon={faClock} className='mr-2' />
                <TimePicker
                  clockIcon={null}
                  clearIcon={null}
                  // format="HH:mm"
                  value={timeSlot?.endTime}
                  onChange={value => {
                    formik.setFieldValue(fieldBaseName + '.endTime', value + ':00')
                  }}
                />
              </div>

            </div>
            <div className='mx-2 my-1'>
              {isError && typeof (formik.errors as any).availableHour[index] === 'string' ? (
                <Error message={(formik.errors as any).availableHour[index] as string} />
              ) : isError && 'startTime' in (formik.errors as any).availableHour[index] && typeof (formik.errors as any).availableHour[index].startTime === 'string' ? (
                <Error message={(formik.errors as any).availableHour[index].startTime as string} />
              ) : isError && 'endTime' in (formik.errors as any).availableHour[index] && typeof (formik.errors as any).availableHour[index].endTime === 'string' ? (
                <Error message={(formik.errors as any).availableHour[index].endTime as string} />
              ) : null}
            </div>
            <div className="d-flex justify-content-center align-items-center mb-2">
              <DaySelection
                daysList={seltectedDays === "NONE" ? headerDays : headerWeekDays}
                field={{
                  name: `${fieldBaseName}.day`,
                  value: timeSlot?.day,
                }}
                scheduledTimes={timeSlot?.day}
              /></div>
            {isError && (
              <Error message={(formik.errors as any).availableHour[index]?.day as string} />)}

          </div>
        )
      })}
      <Button
        color='primary'
        size='sm'
        className='addButton'
        onClick={addTimeslotHandler}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      {isTouched && formik.errors.availableHour && typeof formik.errors.availableHour === 'string' ? (
        <Error message={formik.errors.availableHour} />
      ) : null}
    </div>
  )
}
type MemberCalenderFormProps = {
  onCancel: () => void,
  onSubmit: () => void,
  create?: boolean,
  data?: any,
  memId?: any,
  memUid?: any
}

enum DateType {
  XDaysIntoFuture = 'XDaysIntoFuture',
  Daterange = 'Daterange',
  Indefinitely = 'Indefinitely',
}

function generateTimeSlotsForDate(date: Date, config: FormData): ITimeSlot[] {
  const timeSlots: ITimeSlot[] = []
  const durationMillis = parseInt(config.duration)

  config.availableHour.forEach(({ startTime, endTime }) => {
    let currentStartTime = moment(startTime, 'HH:mm:ss')
    const currentEndTime = moment(endTime, 'HH:mm:ss')

    while (currentStartTime.isBefore(currentEndTime)) {
      const slotEndTime = currentStartTime.clone().add(durationMillis, 'milliseconds')
      timeSlots.push({
        date: moment(date).format('YYYY-MM-DD'),
        startTime: currentStartTime.format('HH:mm:ss'),
        endTime: slotEndTime.format('HH:mm:ss')
      })
      currentStartTime.add((durationMillis + parseInt(config.bufferTime)), 'milliseconds')
      currentStartTime.add((durationMillis + parseInt(config.remainingTime)), 'milliseconds')
    }
  })

  return timeSlots
}

type HolidayItemProps = {
  name: string,
  onEdit: () => void
}

function HolidayItem({
  name,
  onEdit
}: HolidayItemProps) {
  const formik = useFormikContext()
  return (
    <div className={styles.holidayItem}>
      <span className={styles.holidayItem__name}>
        {name}
      </span>
      <Button
        color='link'
        onClick={onEdit}
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    </div>
  )
}

const missingFields = {
  templateStatus: true, // or false depending on your default state
  template: null, // assuming no template selected by default
  templateNodes: null, // assuming no template nodes defined by default
  media: null, // assuming no media attached by default
  startTemplateId: null,
  remainingTemplateId: null,
  endTemplateId: null,
  deleteTemplateId: null,
  templateId: null
};

function MemberCalenderForm({
  onCancel,
  onSubmit,
  create,
  data,
  memId,
  memUid
}: MemberCalenderFormProps) {
  const ctxMenu = useContextMenu();
  const [screen, setScreen] = useState<'MainForm' | 'SelectHoliday' | 'CreateHoliday'>('MainForm')
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [dateType, setDateType] = useState<DateType>(DateType.XDaysIntoFuture)
  const [daysIntoFuture, setDaysIntoFuture] = useState(30)
  const [templateMessage, setTemplateMessage] = useState<any>();
  const getDateTypeForData = (formData: FormData): DateType => {
    if (formData.dateRange.endDate === null) {
      return DateType.Indefinitely
    }
    return DateType.Daterange
  }
  const defaultFormData = useMemo(() => {
    if (data) {
      const formData: FormData = {
        ...data,
        templateStatus: true,
        template: null,
        templateNodes: null,
        media: null,
        startTemplateId: data.startTemplateId,
        remainingTemplateId: data.remainingTemplateId,
        endTemplateId: data.endTemplateId,
        templateId: null,
        holidayForm: null,
        unavailableDate: data.unavailableDate.map((date: string) => ({
          id: uuidv4(),
          date
        })),
        availableHour: data.availableHour.map((val: any) => ({
          id: uuidv4(),
          ...val,
        })),
        duration: String(data.duration),
        bufferTime: String(data.bufferTime),
        remainingTime: String(data.remainingTime),
        memberUid: data.memberUid,
        id: data.id,
        deleteTemplateId: data.deleteTemplateId
      }
      setTemplateMessage(data?.templateMessage);
      setDateType(getDateTypeForData(formData))
      return formData
    } else {
      setDateType(DateType.Daterange)
      return {
        dateRange: {
          startDate: moment().format('YYYY-MM-DD'),
          endDate: moment().add(7, 'days').format('YYYY-MM-DD')
        },
        unavailableDate: [],
        availableHour: [
          { id: uuidv4(), startTime: '09:00:00', endTime: '17:00:00', day: [true, true, true, true, true, true, true] } // 9am to 5pm
        ],
        duration: '1800000',
        bufferTime: '0',
        remainingTime: '0',
        defaultSettings: {
          enableContactReschedule: true
        },
        dateAvailabilityPreset: 'NONE',
        excludeTimeslot: [],
        holidayForm: null
      }
    }
  }, [])

  const memberId = useSelector((state: any) => state.cartreducer.business?.id)
  const memberUid = useSelector((state: any) => state.cartreducer.business?.uid)
  const businessId = useSelector((state: any) => state.cartreducer.business?.business?.id)
  const businessUid = useSelector((state: any) => state.cartreducer.business?.business?.uid);
  const [preview, setPreview] = useState<JSX.Element | null>(null);
  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const channelData = useSelector((state: any) => state.cartreducer.channelUid);
  const channelId = channelData?.value;
  const onSubmitHandler = async (values: FormData, { setValues, setFieldValue }: FormikHelpers<FormData>) => {
    const transformedData = {
      ...values,
      templateStatus: values.templateStatus ? 'APPROVED' : 'DRAFT',
      unavailableDate: values.unavailableDate.map(val => val.date),
      availableHour: values.availableHour.map(val => ({
        startTime: val.startTime,
        endTime: val.endTime,
        day: values.dateAvailabilityPreset === "NONE" ? val.day : val.day.map((d, i, arr) => i >= arr.length - 2 ? false : d)
      })),
      templateMessage: templateMessage
    }

    switch (screen) {
      case 'CreateHoliday':
        try {
          (window as any).PrevFormData = values
          const newList = await appointmentService.createHolidayList({
            data: {
              name: values.holidayForm?.name,
              description: values.holidayForm?.description,
              creator: { id: memberId },
              business: { id: businessId }
            },
            list: values.holidayForm?.list.map(({ name, date }) => ({
              name,
              date
            }))
          })
          setValues({
            ...(window as any).PrevFormData,
            holidayForm: null,
            holidayList: newList.id
          })
          setScreen('MainForm')
          toast('success', 'Holiday list created')
        } catch (error) {
          toast('error', 'Failed to create holiday list')
        }
        break

      default:
        try {
          if (Boolean(create)) {
            await appointmentService.createMemberCalender(memId, transformedData)
            toast('success', 'Calender created successfully')
          } else {
            await appointmentService.updateMemberCalender(memUid, {
              ...data,
              ...transformedData
            })
            toast('success', 'Calender updated successfully')
          }
          onCancel()
        } catch (error) {
          console.error(error)
          toast('error', String('Calender failed to ' + (create ? 'create' : 'update')))
        }
        onSubmit()
        break
    }
  }

  const [openModal, setOpenModal] = useState(false);
  const [modalHead, setModalTiitle] = useState('Select Template');
  const [modalSize, setModalSize] = useState("modal-xxl");
  const [selectedTool, setSelectedTool] = React.useState<string | null>(null);
  const [configuration, setConfiguration] = useState<FormData>();

  const modalTitle = (title: string) => {
    setModalTiitle(title);
  }

  const [selectTemplateId, setSelectTemplateId] = useState<string | null>(null);
  const [selectedTemplateName, setSelectedTemplateName] = useState('');
  const [remainderTemplateId, setRemainderTemplateId] = useState<string | null>(null);
  const [selectedRemainderTemplateName, setSelectedRemainderTemplateName] = useState('');
  const [type, setType] = useState('start');
  const [endTemplateId, setEndTemplateId] = useState<string | null>(null);
  const [selectedEndTemplateName, setSelectedEndTemplateName] = useState('');
  const [showConfig, setShowConfig] = useState(true);
  const [allowedDays, setAllowedDays] = useState<any>([]);
  const [remainderTemplate, setRemainderTemplate] = useState<any>(null);

  const toggleConfig = () => {
    setShowConfig(!showConfig);
  };

  const handleClose = () => {
    setOpenModal(false);
    setPreviewOpen(false);
    setModalSize("modal-xxl");
    setSelectedTool('');
    setPreview(null);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };


  const selectItem = (itemId: string) => {

    if (itemId === "templates") {
      handleOpen();
      setType('start');
    }

    if (itemId === "remainder") {
      handleOpen();
      setType('remainder');
    }

    if (itemId === "end") {
      handleOpen();
      setType('end');
    }

    if (itemId === "delete") {
      handleOpen();
      setType('delete');
    }

  }

  async function updateMember(config: any) {
    try {
      const updatedConfig = await appointmentService.updateMemberCalender(memberUid, config);
      setTemplateMessage(updatedConfig?.templateMessage);
    } catch (error) {
      console.log("Error in updating member", error);
    }
  }

  const schema = yup.object().shape(
    screen === 'CreateHoliday' ? {
      holidayForm: yup.object().shape({
        name: yup.string().required('Name is required'),
        description: yup.string().required('Description is required'),
        list: yup.array().of(yup.object().shape({
          name: yup.string().required('Name is required'),
          date: yup.string().required('Date is required'),
        })).min(1, 'Atleast 1 holiday is required')
      }).required()
    } : {
      dateRange: yup.object().shape({
        startDate: yup.string().required('Start Date is required'),
        endDate: dateType === DateType.Indefinitely ? yup.string().nullable()
          : yup.string().required('End Date is required'),
      }),
      unavailableDate: yup
        .array().of(
          yup.object().shape({
            date: yup.string().required('Date is required')
          })
        ),
      availableHour: yup
        .array().of(
          yup.object().shape({
            startTime: yup.string().required('Start Time is required'),
            endTime: yup.string().required('End Time is required')
              .test('is-greater', 'End time must be after start time', function (endTime) {
                const { startTime } = this.parent
                if (!startTime || !endTime) return true
                return moment(startTime, 'HH:mm:ss').isBefore(moment(endTime, 'HH:mm:ss'))
              }),
            day: yup.array()
              .of(yup.boolean())
              .test('at-least-one-true', 'At least one day must be selected', (days) => {
                return days ? days.some(day => day === true) : false;
              })
              .required('Days are required')
          })
        )
        .min(1, 'Atleast 1 Available Hour is required')
        .required('Available Hour is required'),
      duration: yup.string()
        .matches(/^\d+$/, 'Invalid Duration')
        .test('min-1', 'Duration should be minimum of 1', (value) => {
          const numberValue = parseFloat(value || '')
          return !isNaN(numberValue) && numberValue > 0
        })
        .min(1, '')
        .required('Duration is required'),
      bufferTime: yup.string()
        .matches(/^\d+$/, 'Invalid Buffer Time')
        .test('min-0', 'Buffer Time cannot be less than 0', (value) => {
          const numberValue = parseFloat(value || '')
          return !isNaN(numberValue) && numberValue >= 0
        })
        .min(1, '')
        .required('Buffer Time is required'),
      remainingTime: yup.string()
        .matches(/^\d+$/, 'Invalid Remaining Time')
        .test('min-0', 'Remaining time should be minimum of 1', function (value) {
          const numberValue = parseFloat(value || '');
          const { remainingTemplateId } = this.parent;
          // Allow 0 if remainingTemplateId is empty or null
          if (!remainingTemplateId) {
            return numberValue >= 0;
          }
          return !isNaN(numberValue) && numberValue > 0;
        })
        .required('Buffer Time is required'),
      defaultSettings: yup.object().shape({
        enableContactReschedule: yup.boolean()
      }),
      dateAvailabilityPreset: yup.string(),
      excludeTimeslot: yup.array(),
      holidayForm: yup.object().nullable(),
      startTemplateId: yup.string().nullable(),
      remainingTemplateId: yup.string().nullable(),
      endTemplateId: yup.string().nullable(),
      deleteTemplateId: yup.string().nullable()
    })
  return (
    <div className={styles.wrapper}>
      <Formik
        validationSchema={schema}
        onSubmit={onSubmitHandler}
        initialValues={defaultFormData as FormData}
      >
        {({ handleSubmit, values, errors, touched, handleChange, setFieldValue, setValues }) => {

          const handleSubmission = () => {
            if (values.remainingTime === "0" && values.remainingTemplateId) {
              setFieldValue("remainingTime", "300000")
            }
          }

          useEffect(() => {
            setShowConfig(parseInt(values?.remainingTime) >= 0);
          }, [values?.remainingTime]);
          const timeslots = useMemo(() => {
            if (selectedDate)
              return generateTimeSlotsForDate(selectedDate, values)
            return []
          }, [selectedDate, values])

          const holidayQuery = useQuery({
            queryKey: ['holiady-list', values.dateRange.startDate + '-' + values.dateRange.endDate],
            queryFn: () => appointmentService.getSingleHolidayList({
              id: values.holidayList,
              dateRange: values.dateRange
            }),
            enabled: values.holidayList > 0
          })
          const [remainderName, setRemainderName] = useState<any>(null);
          const [startName, setStartName] = useState<any>(null);
          const [endName, setEndName] = useState<any>(null);
          const [deleteName, setDeleteName] = useState<any>(null);
          const [remainderTemplate, setRemainderTemplate] = useState<any>(null);
          const [startTemplate, setStartTemplate] = useState<any>(null);
          const [endTemplate, setEndTemplate] = useState<any>(null);
          const [deleteTemplate, setDeleteTemplate] = useState<any>(null);

          useEffect(() => {
            if (!remainderTemplate && values?.remainingTemplateId) {
              getTemplate(values.remainingTemplateId, "remaining");
            }
          }, [values?.remainingTemplateId])

          const getTemplate = async (templateId: any, type: string) => {
            const template = await previewTemplate(templateId, channelId);
            if (type === "remaining" && template?.name) {
              setRemainderName(template?.name);
              setRemainderTemplate(template);
            }
            else if (type === "start" && template?.name) {
              setStartName(template?.name);
              setStartTemplate(template)
            }
            else if (type === "end" && template?.name) {
              setEndName(template?.name);
              setEndTemplate(template);
            }
            else if (type === "delete" && template?.name) {
              setDeleteName(template?.name);
              setDeleteTemplate(template);
            }
          }

          useEffect(() => {
            if (!remainderName && values.remainingTemplateId) {
              getTemplate(values.remainingTemplateId, "remaining");
            }
          }, [values.remainingTemplateId])

          useEffect(() => {
            if (!startName && values.startTemplateId) {
              getTemplate(values.startTemplateId, "start");
            }
          }, [values.startTemplateId])

          useEffect(() => {
            if (!endName && values.endTemplateId) {
              getTemplate(values.endTemplateId, "end");
            }
          }, [values.endTemplateId])


          useEffect(() => {
            if (!deleteName && values.deleteTemplateId) {
              getTemplate(values.deleteTemplateId, "delete");
            }
          }, [values.deleteTemplateId])


          const handleSetRemainderTemplateId = (templateId: string, name: string) => {
            setFieldValue('remainingTemplateId', templateId);
            setRemainderName(name);
            getTemplate(templateId, "remaining");
          };

          const handleSetSelectTemplateId = (templateId: string, name: string) => {
            setFieldValue("startTemplateId", templateId);
            setStartName(name);
            getTemplate(templateId, "start");
          };

          const handleSetEndTemplateId = (templateId: string, name: string) => {
            setFieldValue("endTemplateId", templateId);
            setEndName(name);
            getTemplate(templateId, "end");
          };

          const handleSetDeleteTemplateId = (templateId: string, name: string) => {
            setFieldValue("deleteTemplateId", templateId);
            setDeleteName(name);
            getTemplate(templateId, "delete");
          };

          async function fetchMember(variables: {}, media: any, type: string): Promise<void> {
            try {
              let types;
              if (type === 'start') {
                types = 0;
              }
              else if (type === 'remainder') {
                types = 1;
              }
              else if (type === 'end') {
                types = 2;
              }
              else if (type === 'delete') {
                types = 3;
              }
              const members = await appointmentService.getAllMemberConfiguration(businessUid);
              const config = await appointmentService.getMemberCalender(values.memberUid);
              const member = members.find((item: any) => item.id === values.id)
              const isAvailable = member.templateMessage.find((item: any) => item.templateType === type);
              if (isAvailable) {
                config.templateMessage = member.templateMessage.map((item: any) => {
                  if (item.templateType === type) {
                    item.variables = variables;
                    item.media = media;
                  }
                  return item;
                });
              }
              else {
                config.templateMessage = [...config.templateMessage, { variables: variables, memberConfig_id: config.id, media: media, templateType: types }]
              }
              setConfiguration(config);
              updateMember(config);
              setTemplateMessage(config.templateMessage);

            } catch (error) {
              console.log("Error in getting member", error);
            }
          }

          const handleSetTemplateVariables = (variables: [{ variables: {}, media: any, type: string }]) => {
            fetchMember(variables[0].variables, variables[0].media, variables[0]?.type);
          };

          useEffect(() => {
            if (values.holidayList > 0) {
              holidayQuery.refetch()
            }
          }, [
            values.holidayList,
            values.dateRange.startDate,
            values.dateRange.endDate,
          ])


          const deleteSelectedTemplate = async (value: any) => {

            if (value === 'start') {
              const alertResponse = await showAlertYesNo(
                "question",
                "Are you sure you want to delete your start template ?"
              );
              if (alertResponse.isConfirmed) {
                setStartName(null);
                setFieldValue('startTemplateId', null);
              }
            }

            else if (value === 'remainder') {
              const alertResponse = await showAlertYesNo(
                "question",
                "Are you sure you want to delete your reminder template ?"
              );
              if (alertResponse.isConfirmed) {
                setRemainderName(null);
                setFieldValue('remainingTemplateId', null);
              }
            }

            else if (value === 'end') {
              const alertResponse = await showAlertYesNo(
                "question",
                "Are you sure you want to delete your complete template ?"
              );
              if (alertResponse.isConfirmed) {
                setEndName(null);
                setFieldValue('endTemplateId', null);
              }
            }

            else if (value === 'delete') {
              const alertResponse = await showAlertYesNo(
                "question",
                "Are you sure you want to delete your cancel template ?"
              );
              if (alertResponse.isConfirmed) {
                setDeleteName(null);
                setFieldValue('deleteTemplateId', null);
              }
            }

          }

          const handleDateTypeRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const activeType = event.target.name as DateType

            if (dateType == DateType.Daterange && !(window as any).PrevDaterangeValue) {
              (window as any).PrevDaterangeValue = { ...values.dateRange }
            }

            switch (activeType) {
              case DateType.XDaysIntoFuture:
                setFieldValue('dateRange.startDate', moment().format('YYYY-MM-DD'))
                setFieldValue('dateRange.endDate', moment().add(daysIntoFuture, 'days').format('YYYY-MM-DD'))
                break
              case DateType.Indefinitely:
                setFieldValue('dateRange.endDate', null)
                break
              case DateType.Daterange:
                if ((window as any).PrevDaterangeValue) {
                  setFieldValue('dateRange', (window as any).PrevDaterangeValue)
                }
                break
              default:
                break
            }
            setDateType(activeType)
          }

          const isCalenderTileUnavailable = (myDate: Date) => {
            if (values.holidayList > 0
              && holidayQuery.isFetched
              && holidayQuery.data?.id === values.holidayList
              && holidayQuery.data?.listItem.some(holiday => moment(holiday.date, 'YYYY-MM-DD').isSame(moment(myDate), 'date'))
            ) {
              return true;
            }

            const isPastDate = moment().startOf('day').isAfter(moment(myDate).startOf('day'));
            const isWithinDaterange = values.dateRange.endDate === null ||
              moment(myDate).isBetween(
                moment(values.dateRange.startDate).startOf('day'),
                moment(values.dateRange.endDate).endOf('day'),
                'day',
                '[]'
              );
            const isUnavailableDate = values.unavailableDate.some(date => moment(date.date).isSame(moment(myDate), 'date'));
            const prefix = !isWithinDaterange || isUnavailableDate || isPastDate;

            // if (values.dateAvailabilityPreset === 'WORKING_DAYS_ONLY') {
            //   return prefix || myDate.getDay() === 0 || myDate.getDay() === 6;
            // }

            const allowedDaysFunc = (date: any) => {
              const dayOfWeek = moment(date).isoWeekday() - 1; // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
              return !allowedDays[dayOfWeek];
            };

            return prefix || allowedDaysFunc(myDate);
          };



          const markDateUnavailableHandler = (myDate: Date) => {
            const updatedUnavailableDate = [...values.unavailableDate]
            updatedUnavailableDate.push({
              id: uuidv4(),
              date: moment(myDate).format('YYYY-MM-DD')
            })
            setFieldValue('unavailableDate', updatedUnavailableDate)
            setSelectedDate(null)
          }

          const markDateAvailableHandler = (myDate: Date) => {
            const dateStr = moment(myDate).format('YYYY-MM-DD')
            const updatedUnavailableDate = values.unavailableDate.filter(dateObj => {
              return dateObj.date != dateStr
            })
            setFieldValue('unavailableDate', updatedUnavailableDate)
            setSelectedDate(null)
          }

          const excludeTimeslotHandler = (myTimeslot: ITimeSlot) => {
            const updatedExcludeTimeslot = [...values.excludeTimeslot]
            updatedExcludeTimeslot.push(myTimeslot)
            setFieldValue('excludeTimeslot', updatedExcludeTimeslot)
          }

          const unexcludeTimeslotHandler = (myTimeslot: ITimeSlot) => {
            const updatedExcludeTimeslot = values.excludeTimeslot
              .filter(ts => ts.startTime !== myTimeslot.startTime
                && ts.endTime !== myTimeslot.endTime)
            setFieldValue('excludeTimeslot', updatedExcludeTimeslot)
          }

          const createHolidayListHandler = () => {
            setFieldValue('holidayForm', {
              name: '',
              description: '',
              list: []
            } as HolidayListFormData)
            setScreen('CreateHoliday')
          }

          const cancelCreateHolidayHandler = () => {
            setFieldValue('holidayForm', null)
            setScreen('MainForm')
          }

          const calculateDaysIntoFuture = (daysIntoFuture: number, isWorkingDayOnly: boolean) => {
            const currentDate = moment()
            let endDate = moment().add(daysIntoFuture, 'days')

            if (isWorkingDayOnly) {
              let remainingDays = daysIntoFuture
              let tempDate = moment(currentDate)

              while (remainingDays > 0) {
                if (tempDate.day() !== 0 && tempDate.day() !== 6) {
                  remainingDays--
                }
                tempDate.add(1, 'day')
              }

              endDate = moment(tempDate).subtract(1, 'day')
            }

            setFieldValue('dateRange.startDate', moment().add(1, 'day').format('YYYY-MM-DD'))
            setFieldValue('dateRange.endDate', endDate.format('YYYY-MM-DD'))
            setDaysIntoFuture(daysIntoFuture)
          }

          const templatePreview = (type: string, isOpen: boolean) => {
            setPreviewOpen(true);
            let variables = [];
            if(type === "remainder") {
               variables = templateMessage?.find((message: any) => message.templateType === "remainder")?.variables;
            }
            else if (type === "start") {
               variables = templateMessage?.find((message: any) => message.templateType === "start")?.variables;
            }
            else if(type === "end") {
              variables = templateMessage?.find((message: any) => message.templateType === "end")?.variables;
            }
            else if(type === "delete") {
              variables = templateMessage?.find((message: any) => message.templateType === "delete")?.variables;
            }
            const template = type === "remainder"  ? remainderTemplate : type === "start" ? startTemplate : type === "end" ? endTemplate : deleteTemplate;
              return (
                <Modall
                  isOpen={isOpen}
                  onClose={handleClose}
                  title={modalHead}
                  size="modal-xxl"
                >
                  <ChatCamTemplate
                    singleTempData={template}
                    typeOfComponent="APPOINTMENT"
                    templateMapVariables={variables}
                    handleClose={handleClose}
                    type={type}
                    setRemainderId={(templateId: string, name: string) => {handleSetRemainderTemplateId(templateId, name);}}
                    setId={(templateId: string, name: string) => handleSetSelectTemplateId(templateId, name) as unknown as string}
                    setEndId={(templateId: string, name: string) => handleSetEndTemplateId(templateId, name)}
                    setDeleteId={(templateId: string, name: string) => handleSetDeleteTemplateId(templateId, name)}
                    setSVariables={(variable: [{ variables: {}, media: any, type: string }]) => handleSetTemplateVariables(variable)}
                    isEdit={true}
                  />
                </Modall>
              )
          }
                  return (
                  <>
                    <div className={styles.mainContent}>
                      <div className='mt-4'>
                        <div className={styles.calenderPreview}>
                          <Alert color='info' className='text-center'>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                            Make changes to Timeslots by clicking on a date in the Preview Calendar.
                          </Alert>
                          <div className={styles.row}>
                            <Calendar
                              value={selectedDate}
                              tileContent={tile => {
                                const momentObj = moment(tile.date)
                                const shouldDisplayMenu = momentObj.startOf('day').isSameOrAfter(moment().startOf('day')) &&
                                  momentObj.startOf('day').isSameOrAfter(moment(values.dateRange.startDate, 'YYYY-MM-DD').startOf('day')) &&
                                  momentObj.startOf('day').isSameOrBefore(moment(values.dateRange.endDate, 'YYYY-MM-DD').startOf('day'));

                                const MENU_ID = 'CalenderPreview_' + tile.date.toDateString()
                                switch (tile.view) {
                                  case 'year':
                                    return <>{moment(tile.date).format('MMMM')}</>
                                  default:
                                    return (
                                      <>
                                        <div onContextMenu={(event) => ctxMenu.show({ id: MENU_ID, event })}>
                                          {moment(tile.date).format('D')}
                                        </div>
                                        {shouldDisplayMenu ? (
                                          <Menu id={MENU_ID}>
                                            {isCalenderTileUnavailable(tile.date) ? (
                                              <Item
                                                id={"mark-available" + tile.date}
                                                onClick={() => markDateAvailableHandler(tile.date)}
                                              >
                                                Mark as Available
                                              </Item>
                                            ) : (
                                              <Item
                                                id={"mark-unavailable" + tile.date}
                                                onClick={() => markDateUnavailableHandler(tile.date)}
                                              >
                                                Mark as Unavailable
                                              </Item>
                                            )}
                                          </Menu>
                                        ) : null}
                                      </>
                                    )
                                }
                              }}
                              tileDisabled={tile => tile.view === 'month' && isCalenderTileUnavailable(tile.date)}
                              className={styles.calender}
                              onClickDay={date => {
                                setSelectedDate(currDate => currDate && currDate.toString() === date.toString() ? null : date)
                              }}
                              //minDate={moment.max(moment(), moment(values.dateRange.startDate)).toDate()}
                              maxDate={values.dateRange.endDate ? new Date(values.dateRange.endDate) : undefined}
                            />
                            <Collapse in={Boolean(selectedDate)} dimension='height'>
                              <div className={styles.timeSlots}>
                                <p className=''>
                                  <OverlayTrigger
                                    overlay={<Tooltip>Mark as Unavailable</Tooltip>}
                                    placement='bottom'
                                  >
                                    {selectedDate !== null ? (
                                      <button
                                        onClick={() => markDateUnavailableHandler(selectedDate)}
                                        className={styles.unavailableButton}
                                      >
                                        <FontAwesomeIcon icon={faSquareMinus} />
                                      </button>
                                    ) : <></>}
                                  </OverlayTrigger>
                                  {moment(selectedDate, 'YYYY-MM-DD', true).isValid() && moment(selectedDate).format('D MMM YYYY')}
                                </p>
                                <div className={styles.list}>
                                  {timeslots.map(timeslot => {

                                    const MENU_ID = `Timeslot_${timeslot.date}_${timeslot.startTime}_${timeslot.endTime}`
                                    const timeslotCssClasses = [styles.timeSlots__item]
                                    const isExcuded = Boolean(values.excludeTimeslot
                                      .find(ts => ts.date === timeslot.date
                                        && ts.startTime === timeslot.startTime
                                        && ts.endTime === timeslot.endTime))
                                    if (isExcuded) {
                                      timeslotCssClasses.push(styles.excludeTimeslot)
                                    }
                                    const onClickHandler = () => !isExcuded ? excludeTimeslotHandler(timeslot) : unexcludeTimeslotHandler(timeslot)
                                    return (
                                      <div
                                        onClick={onClickHandler}
                                        key={MENU_ID}
                                        className={timeslotCssClasses.join(' ')}
                                        onContextMenu={(event) => ctxMenu.show({ id: MENU_ID, event })}
                                      >
                                        <span className={styles.timestamp}>
                                          {moment(timeslot.startTime, 'HH:mm:ss').format('HH:mm A')}

                                          {' '}to{' '}
                                          {moment(timeslot.endTime, 'HH:mm:ss').format('HH:mm A')}
                                        </span>
                                        <button className={styles.unavailableButton}>
                                          <FontAwesomeIcon icon={isExcuded ? faClose : faCheck} />
                                        </button>
                                        <Menu id={MENU_ID}>
                                          <Item onClick={onClickHandler}>
                                            Mark as {isExcuded ? 'Available' : 'Unavailable'}
                                          </Item>
                                        </Menu>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.sidebar}>
                      <Form onSubmit={handleSubmit}>
                        {screen === 'SelectHoliday' ? (
                          <>
                            <div className={styles.sidebar_header}>
                              <div>
                                <button className={styles.sidebarHeader__back} onClick={() => setScreen('MainForm')}>
                                  <FontAwesomeIcon icon={faArrowLeft} />
                                </button>
                                <span className={styles.sidebarHeader_title}>
                                  Select Holiday List
                                </span>
                              </div>
                              {/* <Button
                          style={{ zIndex: 15550 }}
                          className='signUpBtn'
                          type='button'
                          onClick={createHolidayListHandler}
                        >
                          <FontAwesomeIcon icon={faPlus} className='mr-1' />
                          Create List
                        </Button> */}
                            </div>
                            <SelectHoliday
                              onCancel={() => setScreen('MainForm')}
                            />
                          </>
                        ) : screen === 'CreateHoliday' ? (
                          <>
                            <div className={styles.sidebar_header}>
                              <div>
                                <button
                                  style={{ zIndex: 15550 }} className={styles.sidebarHeader__back} onClick={cancelCreateHolidayHandler}>
                                  <FontAwesomeIcon icon={faArrowLeft} />
                                </button>
                                <span className={styles.sidebarHeader_title}>
                                  Create Holiday List
                                </span>
                              </div>
                            </div>
                            <HolidayForm
                              onCancel={cancelCreateHolidayHandler}
                            />
                          </>
                        ) : (
                          <>
                            <h5 className='my-3'>
                              <FontAwesomeIcon icon={faCalendarAlt} className='mr-2' />
                              Available Dates
                            </h5>
                            <div className='my-3'>
                              <div className='mb-3'>
                                <FormGroup check className='mt-2'>
                                  <Input
                                    type='radio'
                                    checked={dateType === DateType.Daterange}
                                    name={DateType.Daterange}
                                    onChange={handleDateTypeRadioChange}
                                  />
                                  {' '}
                                  <Label check>
                                    Custom date range
                                  </Label>
                                  {dateType === DateType.Daterange ? (
                                    <FormGroup className='mt-2'>
                                      <DateRange />
                                    </FormGroup>
                                  ) : null}
                                </FormGroup>
                                <FormGroup check className='mt-2'>
                                  <Input
                                    type='radio'
                                    name={DateType.XDaysIntoFuture}
                                    checked={dateType === DateType.XDaysIntoFuture}
                                    onChange={handleDateTypeRadioChange}
                                  />
                                  {' '}
                                  <Label check className='d-flex align-items-center'>
                                    <Input
                                      type='number'
                                      min={1}
                                      className='mr-2'
                                      disabled={!(dateType === DateType.XDaysIntoFuture)}
                                      style={{ width: 80 }}
                                      value={daysIntoFuture}
                                      onChange={event => calculateDaysIntoFuture(parseInt(event.target.value), values.dateAvailabilityPreset === 'WORKING_DAYS_ONLY')}
                                      onFocus={() => setDateType(DateType.XDaysIntoFuture)}
                                    />
                                    days into future
                                  </Label>
                                </FormGroup>
                                {/* <FormGroup check className='mt-2'>
                            <Input
                              type='radio'
                              checked={dateType === DateType.Indefinitely}
                              name={DateType.Indefinitely}
                              onChange={handleDateTypeRadioChange}
                            />
                            {' '}
                            <Label check>
                              Indefinitely into the future
                            </Label>
                          </FormGroup> */}
                              </div>
                              <FormGroup className='mt-2'>
                                <Input
                                  type='select'
                                  name='dateAvailabilityPreset'
                                  value={values.dateAvailabilityPreset}
                                  onChange={(event) => {
                                    handleChange(event)
                                    if (dateType === DateType.XDaysIntoFuture) {
                                      calculateDaysIntoFuture(daysIntoFuture, event.target.value === 'WORKING_DAYS_ONLY')
                                    }
                                  }}
                                >
                                  <option value='WORKING_DAYS_ONLY'>
                                    Working days only (Mon-Fri)
                                  </option>
                                  <option value='NONE'>
                                    All days
                                  </option>
                                </Input>
                              </FormGroup>
                              <FormGroup className='mt-2'>
                                <Label>
                                  Holidays
                                  <small className='text-muted ml-2'>(optional)</small>
                                </Label>
                                {values.holidayList > 0 ? holidayQuery.isLoading ? <Spinner /> :
                                  holidayQuery.isError ? (
                                    <Alert color='danger'>{SOMETHING_WENT_WRONG}</Alert>
                                  ) : (
                                    <HolidayItem
                                      name={holidayQuery.data.name}
                                      onEdit={() => setScreen('SelectHoliday')}
                                    />
                                  ) : (
                                  <Button
                                    onClick={() => setScreen('SelectHoliday')}
                                    type='button'
                                    className='d-block signUpBtn'
                                  >
                                    Select Holidays
                                  </Button>
                                )}
                              </FormGroup>
                              <MoreSetting defaultOpen={values.unavailableDate.length > 0}>
                                <FormGroup className='mt-2'>
                                  <Label>
                                    Unavailable Days
                                    <small className='text-muted ml-2'>(optional)</small>
                                  </Label>
                                  <UnavailableDates />
                                </FormGroup>
                              </MoreSetting>
                            </div>
                            <hr />
                            <h5 className='my-3'>
                              <FontAwesomeIcon icon={faClock} className='mr-2' />
                              Available Hours
                            </h5>
                            <div className='my-3'>
                              <FormGroup className='mt-2'>
                                <TimeSlots
                                  seltectedDays={values.dateAvailabilityPreset}
                                  setCalendarDays={setAllowedDays}
                                />
                              </FormGroup>
                              <FormGroup className='mt-2'>
                                <Label className='required mb-0'>Time Frequency</Label>
                                <FormText className='mb-2'>
                                  Set the frequency of available time slots for invitees.
                                </FormText>
                                <DelayInput
                                  name='duration'
                                  value={values.duration}
                                  isInvalid={Boolean(touched.duration && errors.duration)}
                                  isValid={touched.duration && !errors.duration}
                                  error={errors?.duration}
                                />
                              </FormGroup>
                              <MoreSetting defaultOpen={parseInt(values.bufferTime) > 0}>
                                <FormGroup className='mt-2'>
                                  <Label className='mb-0'>
                                    Buffer time
                                    <small className='text-muted ml-2'>(optional)</small>
                                  </Label>
                                  <FormText className='mb-2'>
                                    Add buffer time in between appointments.
                                  </FormText>
                                  <DelayInput
                                    name='bufferTime'
                                    value={values.bufferTime}
                                    isInvalid={Boolean(touched.bufferTime && errors.bufferTime)}
                                    isValid={touched.bufferTime && !errors.bufferTime}
                                    error={errors?.bufferTime}
                                  />
                                </FormGroup>
                              </MoreSetting>
                            </div>

                            {/* <Label>Select Template</Label> */}
                            {/* <br></br> */}
                            <hr />
                            {/* <br></br> */}
                            <div>
                              <FormGroup className='mt-2'>
                                <Label className='mb-0 d-flex'>
                                  Reminder Configuration
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input ml-1"
                                      type="checkbox"
                                      role="switch"
                                      checked={showConfig}
                                      onChange={toggleConfig}
                                    />
                                    <label className="form-check-label" ></label>
                                  </div>
                                </Label>
                                {showConfig && (
                                  <>
                                    <FormText className='mb-2'>
                                      Add reminder time to send template before appointment starts
                                    </FormText>
                                    <DelayInput
                                      name='remainingTime'
                                      value={values.remainingTime !== "0" ? values.remainingTime : "300000"}
                                      isInvalid={Boolean(touched.remainingTime && errors.remainingTime)}
                                      isValid={touched.remainingTime && !errors.remainingTime}
                                      error={errors?.remainingTime}
                                    />
                                  </>
                                )}
                              </FormGroup>
                            </div>
                            {showConfig && (
                              <FormGroup>
                                <br></br>
                                <FormGroup>
                                  <Label className='mb-0'>Template Configuration</Label>
                                  <FormText className='mb-2'>
                                    Set up engaging reminders to keep customers on schedule.
                                  </FormText>
                                </FormGroup>
                                <div className="noteTemplate">
                                  <Button
                                    className='mr-2 signUpBtn mb-1'
                                    id={styles.startTemplate}
                                    type="button"
                                  >Reminder Template</Button>
                                  {openModal && (
                                    <Modall
                                      isOpen={openModal}
                                      onClose={handleClose}
                                      title={modalHead}
                                      size="modal-xxl"
                                    >
                                      <TemplateList
                                        component='APPOINTMENT'
                                        handleClose={handleClose}
                                        modalTitle={modalTitle}
                                        setModalSize={setModalSize}
                                        setRemainderTemplateId={(templateId: string, name: string) => handleSetRemainderTemplateId(templateId, name) as unknown as string}
                                        setTemplateVariables={(variable: [{ variables: {}, media: any, type: string }]) => handleSetTemplateVariables(variable)}
                                        type={type}
                                        setEndTemplateId={(templateId: string, name: string) => handleSetEndTemplateId(templateId, name) as unknown as string}
                                        setSelectTemplateId={(templateId: string, name: string) => handleSetSelectTemplateId(templateId, name) as unknown as string}
                                      />
                                    </Modall>
                                  )}
                                  {(values.remainingTemplateId === null || values.remainingTemplateId === undefined) && (
                                    <a style={{ color: "blue", textDecoration: "underline", marginLeft: "20px" }} onClick={() => { selectItem("remainder"); }}>
                                      Select Template
                                    </a>
                                  )}
                                  {values.remainingTemplateId && (
                                    <label style={{ marginLeft: "20px" }}>
                                      {remainderName && remainderName?.length > 15
                                        ? remainderName?.substring(0, 15) + '...'
                                        : remainderName}
                                    </label>
                                  )}
                                  {isPreviewOpen && (preview)}
                                  {values.remainingTemplateId && (
                                    <>
                                      <Button
                                        color='white'
                                        type='button'
                                        onClick={() => {
                                          setPreview(templatePreview("remainder", true));
                                        }}
                                        className={styles.edit}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                      <Button
                                        type='button'
                                        color='white'
                                        className={styles.removeBttn}
                                        onClick={() => deleteSelectedTemplate("remainder")}
                                      >
                                        <PiMinusCircleFill />
                                      </Button> </>)}
                                </div>
                                <br></br>
                                <div className="noteTemplate">
                                  <Button
                                    className='mr-2 signUpBtn mb-1'
                                    id={styles.startTemplate}
                                    type="button"
                                  >Start Template</Button>
                                  {openModal && (
                                    <Modall
                                      isOpen={openModal}
                                      onClose={handleClose}
                                      title={modalHead}
                                      size="modal-xxl"
                                    >
                                      <TemplateList
                                        component='APPOINTMENT'
                                        handleClose={handleClose}
                                        modalTitle={modalTitle}
                                        setModalSize={setModalSize}
                                        setSelectTemplateId={(templateId: string, name: string) => handleSetSelectTemplateId(templateId, name) as unknown as string}
                                        setTemplateVariables={(variable: [{ variables: {}, media: any, type: string }]) => handleSetTemplateVariables(variable)}
                                        setRemainderTemplateId={(templateId: string, name: string) => handleSetRemainderTemplateId(templateId, name) as unknown as string}
                                        type={type}
                                        setEndTemplateId={(templateId: string, name: string) => handleSetEndTemplateId(templateId, name) as unknown as string}
                                      />
                                    </Modall>
                                  )}
                                  {(values.startTemplateId === null || values.startTemplateId === undefined) && (
                                    <a style={{ color: "blue", textDecoration: "underline", marginLeft: "20px" }} onClick={() => { selectItem("templates") }}>
                                      Select Template
                                    </a>
                                  )}
                                  <label style={{ marginLeft: "20px" }}>
                                    {startName && startName?.length > 15
                                      ? startName?.substring(0, 15) + '...'
                                      : startName}
                                  </label>
                                  {values.startTemplateId && (
                                    <>
                                      <Button
                                        color='white'
                                        onClick={() => setPreview(templatePreview("start", true))}
                                        className={styles.edit}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                      <Button
                                        type='button'
                                        color='white'
                                        className={styles.removeBttn}
                                        onClick={() => deleteSelectedTemplate("start")}
                                      >
                                        <PiMinusCircleFill />
                                      </Button>
                                    </>
                                  )}
                                </div>
                                <br></br>
                                <div className="noteTemplate">
                                  <Button
                                    className='mr-2 signUpBtn mb-1'
                                    id={styles.startTemplate}
                                    type="button"
                                  >Complete Template</Button>
                                  {openModal && (
                                    <Modall
                                      isOpen={openModal}
                                      onClose={handleClose}
                                      title={modalHead}
                                      size="modal-xxl"
                                    >
                                      <TemplateList
                                        component='APPOINTMENT'
                                        handleClose={handleClose}
                                        modalTitle={modalTitle}
                                        setModalSize={setModalSize}
                                        setEndTemplateId={(templateId: string, name: string) => handleSetEndTemplateId(templateId, name) as unknown as string}
                                        setTemplateVariables={(variable: [{ variables: {}, media: any, type: string }]) => handleSetTemplateVariables(variable)}
                                        type={type}
                                        setRemainderTemplateId={(templateId: string, name: string) => handleSetRemainderTemplateId(templateId, name) as unknown as string}
                                        setSelectTemplateId={(templateId: string, name: string) => handleSetSelectTemplateId(templateId, name) as unknown as string}
                                      />
                                    </Modall>
                                  )}
                                  {(values.endTemplateId === null || values.endTemplateId === undefined) && (
                                    <a style={{ color: "blue", textDecoration: "underline", marginLeft: "20px" }} onClick={() => { selectItem("end") }}>
                                      Select Template
                                    </a>
                                  )}
                                  <label style={{ marginLeft: "20px" }}>
                                    {endName && endName?.length > 15
                                      ? endName?.substring(0, 15) + '...'
                                      : endName}
                                  </label>
                                  {values.endTemplateId && (
                                    <>
                                      <Button
                                        color='white'
                                        onClick={() => setPreview(templatePreview("end", true))}
                                        className={styles.edit}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                      <Button
                                        type='button'
                                        color='white'
                                        className={styles.removeBttn}
                                        onClick={() => deleteSelectedTemplate("end")}
                                      >
                                        <PiMinusCircleFill />
                                      </Button>
                                    </>
                                  )}
                                </div>
                                <br></br>
                                <div className="noteTemplate">
                                  <Button
                                    className='mr-2 signUpBtn mb-1'
                                    id={styles.startTemplate}
                                    type="button"
                                  > Cancel Template</Button>
                                  {openModal && (
                                    <Modall
                                      isOpen={openModal}
                                      onClose={handleClose}
                                      title={modalHead}
                                      size="modal-xxl"
                                    >
                                      <TemplateList
                                        component='APPOINTMENT'
                                        handleClose={handleClose}
                                        modalTitle={modalTitle}
                                        setModalSize={setModalSize}
                                        setEndTemplateId={(templateId: string, name: string) => handleSetEndTemplateId(templateId, name) as unknown as string}
                                        setTemplateVariables={(variable: [{ variables: {}, media: any, type: string }]) => handleSetTemplateVariables(variable)}
                                        type={type}
                                        setRemainderTemplateId={(templateId: string, name: string) => handleSetRemainderTemplateId(templateId, name) as unknown as string}
                                        setSelectTemplateId={(templateId: string, name: string) => handleSetSelectTemplateId(templateId, name) as unknown as string}
                                        setDeleteTemplateId={(templateId: string, name: string) => handleSetDeleteTemplateId(templateId, name) as unknown as string}
                                      />
                                    </Modall>
                                  )}
                                  {(values.deleteTemplateId === null || values.deleteTemplateId === undefined) && (
                                    <a style={{ color: "blue", textDecoration: "underline", marginLeft: "20px" }} onClick={() => { selectItem("delete") }}>
                                      Select Template
                                    </a>
                                  )}
                                  <label style={{ marginLeft: "20px" }}>
                                    {deleteName && deleteName?.length > 15
                                      ? deleteName?.substring(0, 15) + '...'
                                      : deleteName}
                                  </label>
                                  {values.deleteTemplateId && (
                                    <>
                                      <Button
                                        color='white'
                                        onClick={() =>  setPreview(templatePreview("delete", true))}
                                        className={styles.edit}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                      <Button
                                        type='button'
                                        color='white'
                                        className={styles.removeBttn}
                                        onClick={() => deleteSelectedTemplate("delete")}
                                      >
                                        <PiMinusCircleFill />
                                      </Button>
                                    </>
                                  )}
                                </div>
                              </FormGroup>
                            )}

                            <div className='my-2 mt-3'>
                              <h5>
                                <FontAwesomeIcon icon={faWrench} className='mr-2' />
                                Default Appointment Settings
                              </h5>
                              <small className='text-muted'>These settings are applied as the default configuration for all your appointments. You have the flexibility to modify them for individual appointments at any time.</small>
                            </div>
                            <FormGroup switch className='my-3'>
                              <Input
                                type='switch'
                                name='defaultSettings.enableContactReschedule'
                                checked={values.defaultSettings.enableContactReschedule}
                                onClick={handleChange}
                              />
                              <Label check>
                                Allow rescheduling
                                <FontAwesomeIcon
                                  icon={faInfoCircle}
                                  id='AllowReschedulingInfo'
                                  className='text-muted ml-2'
                                />
                                <UncontrolledTooltip target='AllowReschedulingInfo'>
                                  Enable rescheduling for your customers to easily change their appointments. You can still reschedule appointments even if this option is disabled.
                                </UncontrolledTooltip>
                              </Label>
                            </FormGroup>
                            <FormGroup className={['mt-2', styles.formActions].join(' ')}>
                              <Button
                                type='button'
                                className='cancelButton mr-2 mt-3 mb-1'
                                onClick={onCancel}
                              >
                                Cancel
                              </Button>
                              <Button
                                // color='primary'
                                className='signUpBtn submitButton pt-2 pb-2'
                                type='submit'
                                onClick={handleSubmission}
                              >
                                {create ? 'Create' : 'Save'}
                              </Button>
                            </FormGroup>
                          </>
                        )}
                      </Form>
                    </div>
                  </>
                  )
        }}
                </Formik>
    </div>
  )
}

MemberCalenderForm.defaultProps = {
  create: false,
  data: null
}

export default MemberCalenderForm