import React, { useEffect, useMemo, useState } from 'react';
import { Form, Offcanvas, Button, Spinner, Tooltip, TooltipProps, OverlayTrigger } from 'react-bootstrap';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import Creatable from 'react-select/creatable';
import { useQuery } from '@tanstack/react-query';

import styles from './updateConversationTag.module.scss';
import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import EditorCaption from '../editorCaption';
import { uuidv4 } from '../../../../utils/uuid';
import { setTagsIcon } from '../../../../icons';
import { useAppSelector } from '../../../../hooks';
import { createTag, getBotTags } from '../../../../services/contacts';
import { toast } from '../../../../../common/alert';
import { type } from 'os';

export interface IContactTag {
    name: string;
    id: number;
    uid: string;
}

interface ContactTagsInputProps {
    value: IContactTag[];
    dynamicValue: boolean;
    dynamicTags: any;
    error: undefined | { value: string }[];
    touched: undefined | { value: boolean }[];
}

export const ContactTagsInput: React.FC<ContactTagsInputProps> = (props) => {
    const formik = useFormikContext();
    const businessUid = useAppSelector((state) => state.meta.businessUid);
    const memberUid = useAppSelector((state) => state.meta.memberUid);

    const tagsQuery = useQuery({
        queryKey: ["contact-tags", businessUid],
        queryFn: () => {
            if (!businessUid) return new Promise(() => ([] as IContactTag[]));
            return getBotTags(businessUid);
        },
    });

    const tags = useMemo(() => {
        if (tagsQuery.isError || tagsQuery.isLoading) {
            return ([] as IContactTag[]);
        }
        return (tagsQuery.data as any).data.tagsDto.map((tag: IContactTag) => ({
            ...tag,
            uid: uuidv4()
        }));
    }, [tagsQuery.data, tagsQuery.isLoading, tagsQuery.isError]);

    const createTagHandler = async (tagName: string) => {
        if (businessUid && memberUid) {
            try {
                const createdTag = await createTag(businessUid, memberUid, tagName);
                const updatedTags = [...props.value];
                updatedTags.push({
                    uid: uuidv4(),
                    id: createdTag.data.id,
                    name: tagName
                });
                formik.setFieldValue('contact.tags', updatedTags);
            } catch (error) {
                toast('error', 'Failed to create new tag');
            }
        }
    };

    const transformTags = (tag: IContactTag) => ({
        value: tag.id,
        label: tag.name
    });

    if (tagsQuery.isLoading) return <Spinner />;
    if (tagsQuery.isError) return <p>Something went wrong, try again later.</p>;

    return (
        <>
            <Form.Label className='d-flex'>
                <input
                    className='me-1'
                    type='checkbox'
                    checked={props.dynamicValue}
                    onChange={(e: any) => {
                        const isChecked = e.target.checked;
                        formik.setFieldValue('contact.dynamicValue', isChecked);
                    }}
                />
                Dynamic Value
            </Form.Label>

            {props.dynamicValue ? (
                <div className={styles.tagsContainer}>
                    <Form.Label>Tags <span className='required'></span></Form.Label>
                    <Field
                        type='text'
                        className='form-control'
                        name='dynamicTags'
                        value={props.dynamicValue && Array.isArray(props.dynamicTags) ? props.dynamicTags : ''}
                        placeholder='Enter the value'
                        onChange={(e: any) => {
                            const { value } = e.target;
                            // Update dynamicTags as an array of strings
                            formik.setFieldValue('contact.dynamicTags', value ? [value] : []);
                        }}
                    />
                </div>
            ) : (
                <>
                    <div className={styles.tagsContainer}>
                        {props.value.map(tag => {
                            const truncatedName = tag.name.length > 30 ? tag.name.substring(0, 20) + '...' : tag.name;
                            const renderTooltip = (props: React.JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
                                <Tooltip id="name-tooltip" {...props}>
                                    {tag.name}
                                </Tooltip>
                            )
                            return (
                                <div className={styles.contactTag} key={tag.uid}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={renderTooltip}
                                    >
                                        <div className={styles.name}>
                                            {truncatedName}
                                        </div>
                                    </OverlayTrigger>
                                    <button
                                        type='button'
                                        onClick={() => {
                                            const updatedTags = props.value.filter((t) => t.id !== tag.id);
                                            formik.setFieldValue('contact.tags', updatedTags);
                                        }}
                                    >
                                        &times;
                                    </button>
                                </div>)
                        })}
                    </div>
                    <Form.Label>Tags <span className='required'></span></Form.Label>
                    <Creatable
                        options={tags.map(transformTags)}
                        isClearable={false}
                        onChange={(selectItem: any) => {
                            const updatedTags = [
                                ...props.value.filter((tag) => tag.id !== selectItem.value)
                            ];
                            updatedTags.push({
                                uid: uuidv4(),
                                id: selectItem.value,
                                name: selectItem.label
                            });
                            formik.setFieldValue('contact.tags', updatedTags);
                        }}
                        onCreateOption={createTagHandler}
                    />
                </>
            )}
        </>
    );
};

interface FormData {
    contact: {
        tags: IContactTag[];
        dynamicTags: string[];
        dynamicValue: boolean;
    };
}

function RemoveConversationTagEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({
        contact: {
            tags: [],
            dynamicTags: [],
            dynamicValue: false,
        },
    });

    const { init, saveElementChanges: saveElement } = useElmentEditor(
        {
            type: 'actions/remove_contactTags',
            data: formData,
        },
        props
    );

    const saveElementChanges = async (values: FormData) => {
        const formattedValues = {
            ...values,
            contact: {
                ...values.contact,
                // Set tags to an empty array if dynamicValue is true
                tags: values.contact.dynamicValue ? [] : values.contact.tags,
                // Map dynamicTags to the desired format if dynamicValue is true
                dynamicTags: values.contact.dynamicValue
                    ? values.contact.dynamicTags.map(tag => (tag)) // Convert to desired format
                    : [], // Or empty if dynamicValue is false
            },
        };

        setFormData(formattedValues)
        // Call the original save function with the formatted values
        await saveElement(formattedValues);
    };
    useEffect(() => init(setFormData), []);

    const tagSchema = yup.object().shape({
        id: yup.number(),
        name: yup.string().required('Tag is required')
    });
    const schema = yup.object().shape({
        contact: yup.object().shape({
            tags: yup.array().when('dynamicValue', {
                is: false,  // Only required when dynamicValue is false
                then: yup.array()
                    .of(yup.object().shape({
                        id: yup.number(),
                        name: yup.string().required('Tag is required')
                    }))
                    .min(1, 'At least one tag is required')
                    .required('At least one tag is required'),
                otherwise: yup.array().notRequired(),
            }),
            dynamicValue: yup.boolean(),
            dynamicTags: yup.array().when('dynamicValue', {
                is: true,  // Only required when dynamicValue is true
                then: yup.array().of(
                    yup.string().required('Tag is required')
                ).min(1, 'Tag is required'),
                otherwise: yup.array().notRequired(),
            }),
        })
    });



    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, values, touched, errors, setValues }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Remove Tags' icon={<img width={20} alt='' src={setTagsIcon} />} />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <ContactTagsInput
                                    value={values.contact.tags}
                                    dynamicValue={values.contact.dynamicValue}
                                    dynamicTags={values.contact.dynamicTags}
                                    error={errors.contact?.tags as undefined | { value: string }[]}
                                    touched={touched.contact?.tags as undefined | { value: boolean }[]}
                                />
                                {touched.contact?.tags && errors.contact?.tags && !Array.isArray(errors.contact?.tags) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.contact?.tags}
                                    </div>
                                ) : null}
                                {values.contact.dynamicValue && touched.contact?.dynamicTags && errors.contact?.dynamicTags ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.contact?.dynamicTags}
                                    </div>
                                ) : null}
                            </Form.Group>
                        </Offcanvas.Body>
                        <div className='editor-footer'>
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default RemoveConversationTagEditor;
