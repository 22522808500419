import axios from "../utils/axios";
import { toast, sweetalert } from "../common/alert";

export const contactDelete = async (selectedUids: [], businessId: any) => {
  if (selectedUids.length > 0) {
    try {
      const deletePayload = {
        data: {
          uid: businessId,
          contactIds: selectedUids,
        },
      };

      const contactDelete = await axios.delete('contact/delete', deletePayload)
      return contactDelete
    }
    catch (error: any) {
      toast('error', error)
    }
  }
}

export const contactGetALL = async (businessId: any, contactPayload: any) => {
  try {
    const response = await axios.post("contact/getAll", contactPayload);
    return response.data
  }
  catch (error: any) {
    toast('error', error)
  }
}

export const memberDelete = async (uid: any) => {
  try {
    const response = await axios.delete(`member/${uid}/delete`)
    if (response) {
      return 'SUCCESS'
    }
  }
  catch (error: any) {
    toast('error', error.response.data.message)
  }
}

export const restoreMember = async (uid: any) => {
  try {
    const response = await axios.patch(`member/${uid}/restore`)
    if (response) {
      return 'SUCCESS'
    }
  }
  catch (error: any) {
    toast('error', error.response.data.message);
  }
}

export const getAllMembers = async (businessId: string, limit: number, page: number, component?: string) => {
  try {
    const MemberList = {
      businessUid: businessId,
      limit: limit,
      page: page,
      status: component === 'ticketing' ? ['ACTIVE'] : ['ACTIVE', 'INACTIVE', 'PENDING']
    };
    const res = await axios.post('member/getMembers', MemberList);
    return res.data;
  }
  catch (error: any) {
    toast('error', error)
  }
}

export const getAllSequence = async (channelUid: string) => {
  try {
    const response = await axios.get(
      `sequence/${channelUid}/getAllSequence?status=Publish`
    );
    return response.data;
  } catch (error: any) {
  }
};

export const subscribeToSequence = async (sequencePayload: any) => {
  try {
    const response = await axios.post(
      `contact/subscribe-to-sequence`, sequencePayload
    );
    return response.data;
  } catch (error: any) {
    toast('error', error)
  }
};

export const contactFilters = async (filterPayload: any) => {
  try {
    const response = await axios.post('contact/filter', filterPayload)
    return response.data
  }
  catch (error: any) {
    toast('error', error)
  }
}

export const companyContactFilters = async (filterPayload: any) => {
  try {
    const response = await axios.post('contact/company/filter', filterPayload)
    return response.data
  }
  catch (error: any) {
    toast('error', error)
  }
}

// teams api

export const getAllTeam = async (payload: any, component?: string) => {
  try {
    const response = await axios.post(`/team/getAll`, payload);

    return response.data;
  } catch (error: any) {
    if (component === 'ticketing')
      return error;
    else
      toast('error', error)
  }
};

export const getAllTeams = async (payload: any, component?: string) => {
  try {
    const response = await axios.post(`/team/getTeams`, payload);
    return response.data;
  } catch (error: any) {
    if (component === 'ticketing')
      return error;
    else
      toast('error', error)
  }
};

export const getTeam = async (Uid: string, component?: string) => {
  try {
    const response = await axios.get(`/team/${Uid}`);
    return response.data;
  } catch (error: any) {
    if (component === 'ticketing')
      return error
    else
      toast('error', error)
  }
};

export const createTeam = async (payload: any) => {
  try {
    const response = await axios.post(`team/create`, payload);
    toast("success", "Created successfully")
    return response.data;
  } catch (error: any) {
    toast('error', error?.response?.data?.message)
  }
};

export const updateTeam = async (uid: string, payload: object) => {
  try {
    const response = await axios.patch(
      `team/${uid}/update`, payload
    );
    toast("success", "Updated Successfully");
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
}

export const teamDelete = async (uid: any) => {
  return new Promise((resolve, reject) => {
    sweetalert(
      'warning',
      'Are you sure you want to delete?',
      () => {
        axios.delete(`team/${uid}/delete`).then(res => {
          toast('success', 'Deleted successfully');
          resolve(res);
        }).catch((err: any) => {
          toast('error', err.response.data.message);
        })
      },
      () => {

      }
    );
  });
}
//-------------------------* GET TAGS *--------------------------------//
export const getAllTags = async (payload: any) => {
  try {
    const response = await axios.post(
      `tag/getAll`, payload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
}

//-------------------------* UPDATE TAGS FOR CONTACTS *--------------------------------//

export const contactBulkUpdate = async (payload: any) => {
  try {
    const response = await axios.post(
      `contact/contactBulkUpdate`, payload
    );
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
}

///////////////////
export const getTeamBasedOnMember = async (payload: any) => {
  try {
    const response = await axios.post(`team/teams`, payload);
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
}