import React, { useState, useEffect, useRef } from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import { Table } from "react-bootstrap";
import {
  Col,
  Row,
  // Pagination,
} from "react-bootstrap";
import "./table.scss";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import Loader from "../shade/Loaders/smallLoader";
import { ErrorImage } from "./ErrorMessage";

type BizTableProps<T extends object> = {
  columns: any;
  fetchData?: (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number,
  ) => Promise<{ data: T[]; totalPages: number }>;
  // initialPageSize: number;
  counts: number;
  refetchData: any;
  tableName: string;
  handleRowClick?: (rowClick: any) => void;
  searchTerm?: string;
  isClear?: boolean;
  isScrolling?: React.Dispatch<React.SetStateAction<string>>
  // onChangeSelection?: (selectedRows: any) => void;
};

function BizTable<T extends object>({
  columns,
  fetchData,
  counts,
  refetchData,
  tableName,
  handleRowClick,
  searchTerm,
  isClear,
  isScrolling
  // onChangeSelection
}: BizTableProps<T>) {
  // console.log(' columns,', columns, 'counts', counts, 'refetchData', refetchData)
  const [data, setData] = useState<T[]>([]);
  const [currentPage, setcurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [isPage, setPage] = useState(false);
  const [isDataNotFound, setIsDataNotFound] = useState(false);
  const [isSameSelected, setSelected] = useState(true);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch()

  const options = [
    { value: 20, label: "20" },
    // { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 500, label: "500" },
    { value: 1000, label: "1000" },
  ];

  const fetchIdRef = React.useRef(0);

  useEffect(() => {
    const container = tableContainerRef.current;

    if (container) {

      const handleScroll = () => {

        if (container.scrollTop !== 0) {
          // console.log("Table is scrolled from the default position");
          if (isScrolling) {
            isScrolling("");
          }
        } else {
          if (isScrolling) {
            isScrolling("1");
          }
        }
      };

      container.addEventListener("scroll", handleScroll);

      // Cleanup function
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true)
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        if (fetchData) {
          const response = await fetchData(
            pageIndex,
            pageSize,
            searchText,
            currentPage
          );
          if (response.data?.length > 0) {
            setLoading(false);
          }
          else if (response.data?.length === 0) {
            setLoading(false);
            setIsDataNotFound(true);
          }
          setData(response?.data);
          setPageCount(response?.totalPages);
        }
      }
    };
    fetchDataAsync();
  }, [fetchData, pageIndex, pageSize, searchText, currentPage]);

  let selectedTableData = useSelector((state: any) => state.tableReducer?.selectedUids);
  const initialSelected = selectedTableData && selectedTableData[tableName + currentPage] || [];
  const initialSelectedRowIds = initialSelected?.reduce((acc: any, row: any) => {
    acc[row.id] = true;
    return acc;
  }, {});


  useEffect(() => {

    if (refetchData) {
      setData(refetchData);
      (tableName === 'BOT' || tableName === 'BOT_FLOW') && !refetchData?.length && (setLoading(false));
      refetchData?.length > 0 && setLoading(false);
      // refetchData.length===0 && setLoading(false);
      refetchData?.length === 0 && counts === 0 && !loading ? setIsDataNotFound(true) : setIsDataNotFound(false);
      if (tableName === 'SEQUENCELOGS' && !refetchData?.length) {
        setLoading(false);
        setIsDataNotFound(true);
      }
    }

  }, [refetchData]);

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 150,
      width: 150,
      maxWidth: 400,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Use the 'page' array instead of the original rows for pagination
    canPreviousPage,
    rows,
    selectedFlatRows,
    isAllRowsSelected,
    canNextPage,
    pageOptions,
    // pageCount,
    manualRowSelectedKey,
    gotoPage,
    nextPage,
    previousPage,
    toggleAllRowsSelected,
    // setPageSize,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getRowId: (row: any) => row?.id,
      initialState: { selectedRowIds: initialSelectedRowIds, pageIndex, pageSize },
      manualPagination: true,
      pageCount,
      getSubRows: (row: any) => row?.subRows || [],
      // useSticky
    } as any,
    usePagination,
    useRowSelect,
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     // Let's make a column for selection
    //     {
    //       id: 'selection',

    //       Header: ({ getToggleAllRowsSelectedProps }:any) => (
    //         <div>
    //           <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
    //         </div>
    //       ),
    //       // The cell can use the individual row's getToggleRowSelectedProps method
    //       // to the render a checkbox
    //       Cell: ({ row }:any) => (
    //         <div>
    //           <input type="checkbox" {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  ) as any;

  const [selectedRows, setSelectedRows] = useState(initialSelectedRowIds || []);
  const [selectedUids, setSelectedUids] = useState<any>(null);

  // const uncheckedData = data.filter((row:any) => !selectedFlatRows.some((selectedRow:any) => selectedRow.original.id === row.id));
  // console.log('Unchecked Data:', uncheckedData);
  const [prevPage, setPrevPage] = useState(0);


  //--------------new-----//
  const prevPageRef = useRef(currentPage);
  const [pageChanged, setPageChanged] = useState(false);
  const [prevCount, setPrevCount] = useState(0);
  const [prevSelectedUids, setPrevSelectedUids] = useState<any>(null);
  const [prevState, setPrevState] = useState<any>(false);
  const [nextsPage, setsPage] = useState(false);
  const [searchedUids, setSearchedUids] = useState<any>(null);
  const [isNext, setIsNext] = useState(false);
  const [mergedUids, setMergedUids] = useState<any>([]);
  const [same, setSame] = useState(true);

  const uniqueUids = (selectedUid: any) => {
    const uniqueSelectedUids = [...new Map(selectedUid.map((item: any) => [item.id, item])).values()];
    return uniqueSelectedUids;
  }

  useEffect(() => {
    if (searchTerm && selectedUids?.length > 0) {
      dispatch({ type: 'SELECTED_DATA', payload: { tableName, selectedUids, currentPage } })
    }
    else if (selectedUids?.length === 0 && searchTerm) {
      dispatch({ type: 'RESET_SELECTED_DATA', payload: { tableName, currentPage } })
    }
  }, [selectedUids]);

  useEffect(() => {
    if (isClear && isClear && searchTerm) {
      setSelectedUids([]);
    }
  }, [isClear])

  useEffect(() => {
    if (!searchTerm && prevSelectedUids && (selectedFlatRows?.length === prevSelectedUids?.length) && !prevState) {
      setPrevState(true);
    }
    if (!searchTerm && prevSelectedUids && pageChanged && selectedFlatRows?.length === 0 && !nextsPage) {
      setsPage(true);
      setSelectedUids([]);
    }
  }, [selectedFlatRows])

  useEffect(() => {
    if (prevState && prevSelectedUids?.length + 1 === selectedFlatRows?.length && selectedFlatRows.length > 0) {
      const hasDifferentIds = selectedUids?.some((selectedUid: any) =>
        !selectedFlatRows?.some((flatRow: any) => flatRow.id === selectedUid.id)
      );
      if (hasDifferentIds) {
        setSelectedUids([...selectedUids, selectedFlatRows[selectedFlatRows?.length - 1].original]);
        setPrevSelectedUids([...prevSelectedUids, selectedFlatRows[selectedFlatRows?.length - 1].original]);
      }
    }

    // used for deselecting the 0 page after searched values 
    if (prevState && (prevSelectedUids?.length - 1 === selectedFlatRows?.length || (selectedFlatRows?.length === 0 && prevSelectedUids?.length !== 0)) && (!isNext || currentPage === 0)) {
      const newSelectedUids = selectedFlatRows.map((row: any) => row.original);
      setSelectedUids(newSelectedUids);
      setPrevSelectedUids(newSelectedUids);
    }
    if (prevState && pageSize === selectedFlatRows?.length && selectedUids?.length - searchedUids?.length !== selectedFlatRows?.length && (!isNext || currentPage === 0)) {
      const newSelectedUids = selectedFlatRows.map((row: any) => row.original);
      setSelectedUids([...searchedUids, ...newSelectedUids]);
      setPrevSelectedUids(newSelectedUids);
    }
    if (nextsPage && (selectedFlatRows?.length > 0 || (selectedFlatRows?.length === 0 && selectedUids?.length !== 0)) && selectedUids?.length !== selectedFlatRows?.length && currentPage !== 0) {
      selectedFlatRows?.length !== 0 && setSelectedUids(selectedFlatRows?.map((row: any) => row.original));
      setIsNext(true);
      selectedFlatRows?.length === 0 && setSelectedUids([]);
    }

  }, [selectedFlatRows])

  useEffect(() => {
    if (!searchTerm && selectedFlatRows?.length !== selectedUids?.length && !prevSelectedUids) {
      setSelectedUids(selectedFlatRows?.map((row: any) => row.original));
    }

    if (searchTerm && selectedFlatRows?.length === 0 && counts !== 0) {
      setPrevCount(selectedUids?.length);
      setPrevSelectedUids(selectedUids);
    }

    if (searchTerm && selectedFlatRows?.length === 1 && counts !== 0 && same) {
      if (selectedUids?.length === 1 && selectedFlatRows[0]?.id === selectedUids[0]?.id) {
        setPrevCount(0);
        setPrevSelectedUids([]);
        setSearchedUids([]);
        setSame(false)
      }
    }

    // used for selecting searched values with previous non searched values
    if (searchTerm && selectedFlatRows?.length > 0 && selectedFlatRows?.length !== selectedUids?.length - prevCount && prevSelectedUids) {
      const newSelectedUids = selectedFlatRows.map((row: any) => row.original);
      setSelectedUids([...prevSelectedUids, ...newSelectedUids]);
      setSearchedUids(newSelectedUids);
      searchedUids?.length > 0 && setSearchedUids([...searchedUids, ...newSelectedUids]);
      prevSelectedUids?.length > 0 && setPrevSelectedUids(uniqueUids([...prevSelectedUids, ...newSelectedUids]))
    }

    if (searchTerm && selectedFlatRows?.length === 0 && (selectedUids?.length - prevCount === 1 || selectedUids?.length - prevCount === counts) && prevSelectedUids && counts !== 0) {
      prevSelectedUids.length !== 0 && setSelectedUids([...prevSelectedUids]);
      setPrevCount(prevSelectedUids.length);
      searchedUids.length > 0 && setSearchedUids([...searchedUids]);
      prevSelectedUids.length !== 0 && prevSelectedUids[0].id === selectedUids[0]?.id  && setSelectedUids([]);
    }
  }, [selectedFlatRows]);
  //--------------end-----//
  useEffect(() => {
    const prevPage = prevPageRef.current;
    if (selectedUids?.length > 0 && !searchTerm) {

      if (prevState && currentPage === 0) {

        const uniqueSelectedUids = [...new Map(selectedUids.map((item: any) => [item.id, item])).values()];
        setSelectedUids(uniqueSelectedUids);
      }
      dispatch({ type: 'SELECTED_DATA', payload: { tableName, selectedUids, currentPage } })
      setPageChanged(false);
    }
    else if (selectedUids?.length === 0 && !pageChanged && !searchTerm) {
      dispatch({ type: 'RESET_SELECTED_DATA', payload: { tableName, currentPage } })
    }
  }, [selectedUids?.length]);

  // useEffect(() => {
  //   const prevPage = prevPageRef.current;
  //   if (selectedUids.length === 0 && !pageChanged ) {
  //     delete selectedTableData[tableName+prevPage];
  //     // dispatch({ type: 'RESET_SELECTED_DATA', payload: { tableName  , currentPage} })
  //     // dispatch({type:'DESELECT_ROW', payload: {tableName, uid: selectedUids}})
  //   }
  //   prevPageRef.current = currentPage;
  // }, [selectedFlatRows, selectedUids]);

  const [previousPath, setPreviousPath] = useState<any>(null); // Rename 'location' state to 'previousPath'
  const location = useLocation();
  useEffect(() => {
    // if (data && !searchTerm) {
    //   dispatch({ type: 'TABLE_DATA', payload: { tableName, data, currentPage } });
    // }
    const currentPath = location.key; // Get current pathname from the location object
    if (currentPath !== previousPath) {
      dispatch({ type: 'TABLE_RESET' }); // Reset table if the pathname has changed
    }
    setPreviousPath(currentPath); // Update 'previousPath' state with the current pathname
  }, [data, location]);
  // Update the dependency array to watch 'location.pathname'


  const onSearchChange = (e: any) => {
    setSearchText(e.target.value);
    setPageIndex(0); // Reset to first page on search
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      // width: "200px",
      cursor: "pointer",
    }),
    menu: (provided: any) => ({
      ...provided,
      width: "80px",
      alignItems: "center",
      zIndex: 10,
      // Adjust the menu width here
    }),
    option: (
      provided: any,
      state: { isSelected: boolean; isFocused: boolean }
    ) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#00B598"
        : state.isFocused
          ? "#458d80"
          : "inherit", // Change the background color of selected option
      color: state.isSelected || state.isFocused ? "white" : "black", // Change the text color of selected option
      accentColor: "#009980",
    }),
  };
  const handlePageSizeChange = (selectedOption: any) => {
    setPageSize(selectedOption.value);
    dispatch({ type: 'TABLE_RESET' });
    gotoPages(0);
  };

  //pagination-for table
  const gotoPages = (pageNumber: number) => {
    setPrevPage(currentPage);
    setcurrentPage(pageNumber);
    setPageChanged(true);
    setPage(true);
    // Update the active page number
  };

  const visiblePages = 5; // Number of pagination items to display

  let startPage = Math.max(pageSize - Math.floor(visiblePages / 2), 0);
  let endPage = startPage + visiblePages - 1;

  if (endPage >= pageCount) {
    endPage = pageCount - 1;
    startPage = Math.max(endPage - visiblePages + 1, 0);
  }

  const totalPages = Math.ceil(counts / pageSize);
  const pageNumbers = Array.from(
    { length: endPage - startPage + 5 },
    (_, idx) => startPage + idx
  );

  const tableStyles = tableName === 'APPOINTMENTS' && counts === 0 ? 'paginationContainer transparent' : 'paginationContainer';
  const tableContainer = tableName === 'APPOINTMENTS' && counts === 0 ? `tableContainer ${tableName}-container transparent` : `tableContainer ${tableName}-container`

  const getPageNumbers = () => {
    const startPage = Math.max(currentPage - 2, 1);
    const endPage = Math.min(startPage + 4, pageCount);
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, idx) => startPage + idx
    );
  };
  return (
    <div>

      <div className={tableContainer}>
        <Table {...getTableProps()} className="scrollTable">
          <tbody {...getTableBodyProps()} className={`scrollTable tableBody ${tableName}`} ref={tableContainerRef}>
            <>

              <div id="table-header">
                {headerGroups.map((headerGroup: any) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    style={{ border: "none" }}
                  >
                    {headerGroup.headers.map((column: any) => (
                      <th
                        {...column.getHeaderProps()}
                        className={column.id === 'apiKey' ? 'apiKeys-head' : `${column.id}-cell`}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </div>
              {loading && tableName !== 'APIKEY'? (
                <Loader />
              ) : (<>
                {page.map((row: any) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} onClick={() => handleRowClick && handleRowClick(row?.original)}>
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`${cell.column.id}-cell`}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>)}
            </>
          </tbody>
        </Table>
      </div>

      <div className={tableStyles} >
        {(counts >= pageSize) && tableName !== 'BOT_FLOW' ? (
          <Row>
            <Col md="6">
              <ReactPaginate
                forcePage={currentPage}
                initialPage={0}
                breakLabel="..."
                nextLabel=">"
                onClick={(clickEvent: any) => {
                  const nextPage =
                    clickEvent.nextSelectedPage ?? clickEvent.selected;
                  gotoPages(nextPage);
                }}
                // onPageActive = {(selectedItem: { selected: number })=>console.log('selected',selectedItem)}
                pageRangeDisplayed={2}
                pageCount={totalPages}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName="react-paginate" // Use for the whole pagination container
                pageLinkClassName="page-link" // Use for page numbers
                previousLinkClassName="page-link" // Use for the "Previous" link
                nextLinkClassName="page-link" // Use for the "Next" link
                activeLinkClassName="active"
                disabledClassName="disabled"
              />
            </Col>
            <Col md="5">
              <div className="paginate-dropdown">
                {counts >= 20 && (
                  <Select
                    className="text-center"
                    value={options.find((option) => option.value === pageSize)}
                    onChange={handlePageSizeChange}
                    options={options}
                    styles={customStyles}
                    menuPlacement="auto"
                    isSearchable={false}
                  />
                )}
              </div>
            </Col>
          </Row>
        ) : <Row>
          <Col md="11">
            <div className="paginate-dropdown">
              {counts >= 20 && (
                <Select
                  className="text-center"
                  value={options.find((option) => option.value === pageSize)}
                  onChange={handlePageSizeChange}
                  options={options}
                  styles={customStyles}
                  menuPlacement="auto"
                  isSearchable={false}
                />
              )}
            </div>
          </Col>
        </Row>}
      </div>

      {(isDataNotFound && !counts && refetchData?.length === 0 && tableName !== 'BOT'
        && tableName !== 'APIKEY' && tableName !== 'BOT_FLOW') && (
          <>
            <ErrorImage />
          </>
        )}
    </div>
  );
}

export default BizTable;
